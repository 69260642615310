import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import Spinner from "../Spinner/Spinner";
import {
  formatDateWithDay,
  formatDateWithDayShortDay,
} from "../../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
const UpcomingRides = () => {
  const {
    data: upcomingRides,
    isLoading,
    fetchItems: getRides,
    error,
  } = useCRUD("/api/upcoming-events-with-rides");

  useEffect(() => {
    getRides().catch((err) => {
      console.error("Error fetching events:", err);
    });
  }, [getRides]);

  if (isLoading) return <Spinner />;
  if (error) return <p>Could not load upcoming rides: {error.message}</p>;
  if (!upcomingRides || upcomingRides.length === 0) {
    return (
      <div className="card bg-white shadow-lg max-w-4xl mx-auto p-6 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">
          No Upcoming Rides Scheduled
        </h2>
      </div>
    );
  }

  const getStatusClass = (status) => {
    switch (status) {
      case "On-Route":
        return "bg-warning text-white"; // Update these classes as needed
      case "Arrived":
        return "bg-success text-white"; // Update these classes as needed
      default:
        return "bg-gray-100 text-gray-800"; // Default state
    }
  };

  return (
    <div className="card bg-white shadow-lg max-w-4xl mx-auto p-6 rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
        Active Rides
      </h2>
      <div className="mt-6">
        {upcomingRides.map((event) => (
          <div
            key={event.id}
            className="card bg-white shadow overflow-hidden rounded-md p-4 mb-4"
          >
            <div className="flex justify-between items-center mb-4">
              <div>
                <p className="text-lg font-bold text-primary">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    size="lg"
                    className="text-primary flex-shrink-0"
                  />
                  &nbsp;
                  {event.title} -{" "}
                  {formatDateWithDayShortDay(event.dateOfOccurrence)}
                </p>
              </div>
            </div>
            <ul className="divide-y divide-gray-200">
              {event.event_rides.map((ride, index) => (
                <li
                  key={index}
                  className="py-3 flex items-center justify-between relative"
                >
                  <span
                    className={`absolute top-0 left-0 inline-block text-xs font-semibold px-2.5 py-0.5 rounded-br-lg ${getStatusClass(
                      ride.pickupStatus
                    )}`}
                  >
                    {ride.pickupStatus || "Pending"}
                  </span>
                  {ride.pickupTime && (
                    <span
                      className={`absolute top-0 right-0 inline-block text-xs font-semibold py-0.5 px-0.5 rounded-bl-lg  bg-gray-100 text-gray-800"`}
                    >
                      <i className="fas fa-clock"></i>&nbsp; {ride.pickupTime}
                    </span>
                  )}

                  <span className="flex-1 min-w-0 pt-6 pl-6">
                    {" "}
                    <span className="text-sm font-bold text-gray-800">
                      {ride.name}
                    </span>
                    {ride.noOfPpl > 1 && (
                      <span className="ml-2 inline-block bg-[#004a7e] text-white py-0.5 px-2 rounded-full text-xs">
                        <FontAwesomeIcon icon={faUsers} /> {ride.noOfPpl}
                      </span>
                    )}
                    <div className="text-sm text-gray-500">
                      {ride.pickupLocation}
                    </div>
                  </span>
                  <div className="ml-0 inline-block text-xs font-semibold px-2.5 py-0.5 rounded bg-[#ff9100] text-white">
                    <span>
                      <i className="fas fa-car-side"></i> &nbsp;
                      {ride.driver}
                    </span>{" "}
                  </div>
                  {/* &nbsp;
                  {ride.pickupTime && (
                    <div className="ml-0 bg-info text-white text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                      <i className="fas fa-clock"></i>&nbsp; {ride.pickupTime}
                    </div>
                  )} */}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingRides;
