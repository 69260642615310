import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import { formatDate } from "../utils/dateUtils";
import RsvpSubmitted from "../components/Event/RsvpSubmitted";
import Spinner from "../components/Spinner/Spinner";

const ParticipantRsvp = () => {
  const { uniqueCode } = useParams();
  const {
    data: eventParticipation,
    fetchItems: fetchEventParticipation,
    isLoading,
    error,
  } = useCRUD(`/api/event-participations/rsvp/${uniqueCode}`);
  const [needRide, setNeedRide] = useState("");
  const [rideLocation, setRideLocation] = useState("");
  const [initialRideLocation, setInitialRideLocation] = useState("");
  const [initialMessageForAdmin, setInitialMessageForAdmin] = useState("");
  const [messageForAdmin, setMessageForAdmin] = useState("");

  const { updateItem } = useCRUD(`/api/event-participations/rsvp`);
  const [rsvpStatus, setRsvpStatus] = useState({
    submitted: false,
    message: "",
  });

  useEffect(() => {
    fetchEventParticipation();
  }, [fetchEventParticipation]);

  useEffect(() => {
    if (eventParticipation) {
      setNeedRide(eventParticipation.needRide);
      setRideLocation(eventParticipation.rideLocation || "");
      setInitialRideLocation(eventParticipation.rideLocation || "");
      setMessageForAdmin(eventParticipation.messageForAdmin || ""); // Initialize messageForAdmin
      setInitialMessageForAdmin(eventParticipation.messageForAdmin || "");
    }
  }, [eventParticipation]);

  useEffect(() => {
    document.body.style.backgroundImage =
      "url('your-background-image-url-here')";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";

    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  const handleRsvpChange = async () => {
    const updatePayload = {
      // If `needRide` is "YesDiff", send it as "Yes", otherwise send the current value
      needRide: needRide === "YesDiff" ? "Yes" : needRide,
      // Include `rideLocation` if `needRide` is "YesDiff" and `rideLocation` has been modified
      ...(needRide === "YesDiff" &&
        rideLocation !== initialRideLocation && { rideLocation }),
      ...(messageForAdmin !== initialMessageForAdmin && { messageForAdmin }),
    };

    updateItem(uniqueCode, updatePayload)
      .then((data) => {
        // Handle success, data contains the parsed JSON response
        setRsvpStatus({
          submitted: true,
          message: "Your RSVP has been recorded.",
        });
      })
      .catch((error) => {
        // Handle error, error.message contains the error message
        console.error("Error updating RSVP:", error);
        setRsvpStatus({
          submitted: false,
          message:
            error.message || "Failed to record your RSVP. Please try again.",
        });
      });
  };

  // If RSVP has been submitted, show the message instead of the form
  if (rsvpStatus.submitted) {
    return <RsvpSubmitted message={rsvpStatus.message} />;
  }

  if (isLoading) return <Spinner />;
  if (error)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  if (!eventParticipation)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  if (eventParticipation.isDriver)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  const { name, eventDetails } = eventParticipation;

  const currentDate = new Date();
  const eventDate = new Date(eventDetails.dateOfOccurrence);

  // Add 12 hours to eventDate in UTC
  eventDate.setUTCHours(eventDate.getUTCHours() + 12);

  // If you want to compare the current date in UTC as well
  // Convert currentDate to UTC by creating a new Date object in UTC
  const currentUTCDate = new Date(
    Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    )
  );

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        backgroundImage: "url('/background.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {" "}
      <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow">
        {eventDate < currentUTCDate ? (
          <div className="text-center text-red-500 p-4">
            Event RSVP expired.
          </div>
        ) : (
          <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
            {/* Display accountLogo if present */}
            {eventParticipation.accountLogo && (
              <div className="text-center mb-4">
                <img
                  src={eventParticipation.accountLogo}
                  alt="Account Logo"
                  className="mx-auto"
                  style={{ maxWidth: "150px", maxHeight: "150px" }} // Limiting size
                />
              </div>
            )}
            <h1 className="text-xl font-semibold">Hi {name},</h1>
            <p>
              Do you need a ride for <strong>{eventDetails.title}</strong> on{" "}
              <strong>{formatDate(eventDetails.dateOfOccurrence)}</strong>?
            </p>
            <div className="flex flex-col gap-4">
              <div
                className={`p-4 rounded-lg cursor-pointer text-center ${
                  needRide === "Yes" ? "bg-green-500 text-white" : "bg-gray-200"
                }`}
                onClick={() => {
                  setNeedRide("Yes");
                  setRideLocation(eventParticipation.rideLocation);
                }}
              >
                Yes, need pickup from {eventParticipation.rideLocation}
              </div>
              <div
                className={`p-4 rounded-lg cursor-pointer text-center ${
                  needRide === "YesDiff"
                    ? "bg-green-500 text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setNeedRide("YesDiff")}
              >
                Yes, need pickup from a different location
              </div>
              {needRide === "YesDiff" && (
                <div>
                  <label className="label"> New Ride Location: </label>
                  <input
                    type="text"
                    name="rideLocation"
                    value={rideLocation}
                    onChange={(e) => setRideLocation(e.target.value)}
                    className="input input-bordered w-full"
                    placeholder="Enter new ride location"
                  />
                </div>
              )}
              <div
                className={`p-4 rounded-lg cursor-pointer text-center ${
                  needRide === "No" ? "bg-red-500 text-white" : "bg-gray-200"
                }`}
                onClick={() => setNeedRide("No")}
              >
                Don't need a ride
              </div>
            </div>
            <div>
              <input
                type="text"
                name="messageForAdmin"
                value={messageForAdmin}
                onChange={(e) => setMessageForAdmin(e.target.value)}
                className="input input-bordered w-full"
                placeholder="Enter message for admin if needed"
              />
            </div>
            <button
              className="btn btn-primary w-full"
              onClick={handleRsvpChange}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ParticipantRsvp;
