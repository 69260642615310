import { useState, useCallback } from "react";

const useCRUD = (endpoint) => {
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  const fullUrl = `${apiBaseUrl}${endpoint}`; // Combine base URL with endpoint

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getToken = () => localStorage.getItem("userToken");

  // Function to create headers with token
  const createHeaders = () => {
    const token = getToken();
    return {
      "Content-Type": "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    };
  };

  // Fetch data (Read)
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl, {
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();
      setData(fetchData?.data ? fetchData.data : fetchedData);
      return fetchedData?.data ? fetchedData.data : fetchedData;
    } catch (err) {
      setError(err);
      return null; // Return null in case of an error
    } finally {
      setIsLoading(false);
    }
  }, [fullUrl]);

  // Fetch data (Read)
  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl, {
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedData = await response.json();
      setData(fetchedData?.data ? fetchedData.data : fetchedData);
    } catch (err) {
      setError(err);
      return err;
    } finally {
      setIsLoading(false);
    }
  }, [fullUrl]);

  // Add item (Create)
  const addItem = async (item) => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: createHeaders(),

        body: JSON.stringify(item),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
      //fetchItems();
    } catch (err) {
      setError(err);
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  const addLogo = async (item) => {
    setIsLoading(true);
    try {
      const response = await fetch(fullUrl, {
        method: "POST",
        headers: {
          ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {}),
          Accept: "application/json",
          // Note: Don't set 'Content-Type' for FormData; the browser will set it correctly with the boundary
        },
        body: item,
        credentials: "include", // If your API requires cookies/session
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json();
      //fetchItems();
    } catch (err) {
      setError(err);
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  // Update item (Update)
  const updateItem = async (id, updatedItem) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${fullUrl}/${id}`, {
        method: "PUT",
        headers: createHeaders(),

        body: JSON.stringify(updatedItem),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json(); // Parse JSON body of the response and return it
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete item (Delete)
  const deleteItem = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${fullUrl}/${id}`, {
        method: "DELETE",
        headers: createHeaders(),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    addItem,
    fetchItems,
    updateItem,
    deleteItem,
    fetchData,
    addLogo,
  };
};

export default useCRUD;
