import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GroupCard from "../components/Group/GroupCard";
import useCRUD from "../hooks/useCRUD";

const Groups = () => {
  const { data: groups, fetchItems: getGroups } = useCRUD(
    "/api/participant-groups"
  );

  useEffect(() => {
    getGroups().catch((err) => {
      console.error("Error fetching groups:", err);
    });
  }, [getGroups]);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold ml-3 mb-6">My Groups</h2>
      {groups && groups.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {groups.map((group) => (
            <GroupCard key={group.id} group={group} />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">
          No groups found. Start by creating a new event.
        </p>
      )}
    </div>
  );
};

export default Groups;
