import React, { useState, useEffect } from "react";
import Spinner from "../components/Spinner/Spinner";

import UpcomingEvents from "../components/Dashboard/UpcomingEvents";
import version from "../version.json";
import UpcomingRides from "../components/Dashboard/UpcomingRides";

const Home = () => {
  // State to hold the user data
  const [user, setUser] = useState(null);

  const [isNewVersion, setIsNewVersion] = useState(false);

  useEffect(() => {
    // Fetch the user info from localStorage when the component mounts
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      setUser(JSON.parse(userInfo));
    }

    // Version check
    const storedVersion = localStorage.getItem("appVersion");
    const currentVersion = version.version;

    if (storedVersion && storedVersion !== currentVersion) {
      setIsNewVersion(true);
      localStorage.setItem("appVersion", currentVersion);
    } else if (!storedVersion) {
      localStorage.setItem("appVersion", currentVersion);
    }
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//code.tidio.co/zpl1twxpf7tejzunui2f2tor4y8641ci.js";
    script.async = true;
    document.body.appendChild(script);

    // Optional: Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // The empty array ensures the effect runs only once after the initial render

  if (!user) {
    return <Spinner />;
  }

  return (
    user && (
      <div className="min-h-screen bg-gray-100 p-4">
        {/* Version Update Alert */}
        {isNewVersion && (
          <div className="bg-warning border-warning text-primary p-3 rounded-lg mb-4">
            <strong>New Version Available:</strong> A new version of the app is
            available.{" "}
            <a href="/version-history" className="underline">
              See what's changed
            </a>
          </div>
        )}
        {/* Welcome Banner */}
        <div className="bg-primary text-white p-4 rounded-lg shadow-lg">
          <h1 className="text-xl md:text-2xl font-bold">
            Welcome, {user.name}!
          </h1>
        </div>

        {/* Upcoming Events */}
        <section className="mt-6">
          <UpcomingEvents />
        </section>

        {/* Upcoming Rides */}
        <section className="mt-6">
          <UpcomingRides />
        </section>
      </div>
    )
  );
};

export default Home;
