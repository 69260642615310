import React, { useState, useRef, useEffect } from "react";
import Spinner from "../Spinner/Spinner"; // Assuming you have this component

import Autocomplete from "../Elements/Autocomplete";
import useCRUD from "../../hooks/useCRUD";
import { formatPhoneNumberForSms } from "../../utils/phoneNumberUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble, faUsers } from "@fortawesome/free-solid-svg-icons";

const DriverCard = ({
  driver,
  rides,
  onSendNotification,
  isLoading,
  allParticipants,
  onManagePickups,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const cardRef = useRef(null); // Reference for the entire card
  const [messageForDriver, setMessageForDriver] = useState("");

  const [isManaging, setIsManaging] = useState(false);
  const [isManagingMessage, setIsManagingMessage] = useState(false);

  const { updateItem } = useCRUD("/api/event-participations"); // Adjust with actual API path

  const toggleMenu = () => setShowMenu(!showMenu);

  const handleMessageChange = (e) => {
    setMessageForDriver(e.target.value);
  };

  const handleSendMessage = () => {
    setMessageForDriver(driver.messageFromAdmin);
    setIsManagingMessage(true);
    setShowMenu(false);
  };

  const handleSubmitMessage = async () => {
    if (!messageForDriver) return; // Prevent empty messages
    // Implement your API call logic here
    await updateItem(driver.id, { messageFromAdmin: messageForDriver });
    setMessageForDriver(""); // Clear the message input after submission
    alert("Message added."); // Provide feedback to the user
    setIsManagingMessage(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the entire card
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        if (isManaging) {
          setIsManaging(false); // Reset isManaging only if the click is outside the card
        }
        setShowMenu(false); // You may still want to close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isManaging]);

  const handleAddParticipant = (participantId) => {
    // Function to add participant to driver's list
    onManagePickups(driver.participantId, participantId, "add");
  };

  const handleRemoveParticipant = (participantId) => {
    // Function to remove participant from driver's list
    console.log("Removing participant", participantId);
    console.log("Driver ID:", driver);
    onManagePickups(driver.id, participantId, "remove");
  };

  const handleMenuSelection = (action) => {
    // Perform action based on the selection
    if (action === "sendNotification") {
      onSendNotification(driver.id);
    } else if (action === "managePickups") {
      setIsManaging(!isManaging);
    }
    // Close the menu
    setShowMenu(false);
  };

  // Filter participants who need a ride and are not assigned yet
  const availableParticipants = allParticipants?.filter(
    (p) => p.needRide === "Yes" && !p.assignedDriverId
  );

  return (
    <div ref={cardRef} className="card bg-base-100 shadow-xl">
      {" "}
      {!driver.confirmedPickupAssignment && driver.pickupNotificationSent && (
        <div
          className="tooltip"
          data-tip="Pickup assignments have been sent to driver."
        >
          <div className="absolute top-0 left-0 bg-blue-500 text-white text-center py-0.5 px-2 text-xs rounded-r-lg">
            Notification Sent
          </div>
        </div>
      )}
      {!driver.confirmedPickupAssignment && !driver.pickupNotificationSent && (
        <div
          className="tooltip"
          data-tip="Hasn't received a notification yet about pickup assignments. Click card menu to send individual notification"
        >
          <div className="absolute top-0 left-0 bg-yellow-500 text-white text-center py-0.5 px-2 text-xs rounded-r-lg">
            Notification Pending
          </div>
        </div>
      )}
      {driver.confirmedPickupAssignment && (
        <div
          className="tooltip"
          data-tip="Pickup assignments have been confirmed by driver."
        >
          <div className="absolute top-0 left-0 bg-green-500 text-white text-center py-0.5 px-2 text-xs rounded-r-lg">
            Confirmed
          </div>
        </div>
      )}
      {/* Use the cardRef here */}
      <div className="card-body">
        <h2 className="card-title">
          <i className="fas fa-car-side"></i> &nbsp;
          <a href={`sms:${formatPhoneNumberForSms(driver.phone)}`}>
            {driver.name}
          </a>
          <div className="badge bg-green-700 text-white ml-2 py-1">
            {rides.reduce(
              (total, participant) => total + participant.noOfPpl,
              0
            )}{" "}
            &nbsp;
            <FontAwesomeIcon icon={faUsers} />
          </div>
          <button
            onClick={toggleMenu}
            className="btn btn-square btn-ghost absolute right-2 top-2"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
          {showMenu && (
            <div
              ref={menuRef}
              className="absolute right-0 mt-12 w-48 bg-white rounded-md shadow-xl z-10"
            >
              <ul className=" list-none text-sm py-1 text-gray-700">
                <li>
                  <button
                    onClick={() => handleMenuSelection("sendNotification")}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Send Notification
                  </button>
                </li>
                <li>
                  <button
                    onClick={() =>
                      window.open("/r/" + driver.uniqueCode, "_blank")
                    }
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Pickup Link
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleMenuSelection("managePickups")}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Manage Pickups
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleSendMessage}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  >
                    Add Message For Driver
                  </button>
                </li>
              </ul>
            </div>
          )}
        </h2>

        {isManagingMessage && (
          <div className="mt-4">
            <textarea
              value={messageForDriver}
              onChange={handleMessageChange}
              className="textarea textarea-bordered w-full"
              placeholder="Type your message here.This will appear on driver's pickup page."
            ></textarea>
            <button
              onClick={handleSubmitMessage}
              className="btn btn-primary mt-2"
            >
              Submit Message
            </button>
          </div>
        )}
        {isManaging && (
          <>
            <Autocomplete
              options={availableParticipants}
              onSelect={handleAddParticipant}
              placeholder="Add participant..."
            />
            {rides.map((participant) => (
              <div
                key={participant.participantId}
                className="flex justify-between items-center"
              >
                <span>
                  {participant.name}{" "}
                  {participant.noOfPpl > 1 ? `(${participant.noOfPpl})` : ""}-{" "}
                  {participant.rideLocation}
                </span>
                <button
                  className="btn btn-error  text-white btn-xs"
                  onClick={() => handleRemoveParticipant(participant)}
                >
                  Remove
                </button>
              </div>
            ))}
          </>
        )}
        {!isManaging && (
          <ul>
            {rides.map((participant) => (
              <li key={participant.participantId}>
                <span>
                  {participant.name}
                  {participant.noOfPpl > 1
                    ? `(${participant.noOfPpl})`
                    : ""} - {participant.rideLocation}
                  {participant.pickupNotificationSent && (
                    <div className="tooltip" data-tip="Driver Assignment sent">
                      <FontAwesomeIcon
                        icon={faCheckDouble}
                        className="text-blue-500 ml-2"
                      />
                    </div>
                  )}
                </span>
              </li>
            ))}
          </ul>
        )}
        {driver.messageFromAdmin && (
          <>
            <span
              className="tooltip text-left"
              data-tip={`Message for driver: ${driver.messageFromAdmin}`}
            >
              <p className="mt-4">
                <i className="fas fa-solid fa-envelope"></i>
              </p>
            </span>
          </>
        )}
        {isLoading && <Spinner />}
      </div>
    </div>
  );
};

export default DriverCard;
