import React from "react";
import {
  formatPhoneNumberForSms,
  formatPhoneNumber,
} from "../../utils/phoneNumberUtils";
import KebabMenu from "../Elements/KebabMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const PassengerDetail = ({ passenger, openTimeModal, openStatusModal }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case "On-Route":
        return "bg-warning text-white"; // Use appropriate classes for your warning state
      case "Arrived":
        return "bg-success text-white"; // Use appropriate classes for your success state
      default:
        return "bg-gray-100 text-gray-800"; // Default state
    }
  };
  return (
    <div
      key={passenger.id}
      className="relative p-4 border-b flex justify-between items-center"
    >
      <div className="flex-grow">
        {passenger.name}
        {passenger.noOfPpl > 1 && (
          <div className="tooltip" data-tip="No. of People">
            <span className="ml-2 bg-[#004a7e] text-white py-1 px-2  rounded-sm text-xs">
              {passenger.noOfPpl} <FontAwesomeIcon icon={faUsers} />
            </span>
          </div>
        )}{" "}
        <div className="text-sm text-gray-500">
          <a
            href={`sms:${formatPhoneNumberForSms(passenger.phone)}`}
            className="font-bold hover:text-blue-700"
          >
            {formatPhoneNumber(passenger.phone)}
          </a>
        </div>
        <div className="text-sm text-gray-500">
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              passenger.rideLocation
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            {passenger.rideLocation}
          </a>
        </div>
        {passenger.pickupTime && (
          <div
            className="tooltip"
            data-tip="Pickup Time. You can change it from the menu on the right. A notification will go out to the person after you set pickup time."
          >
            <span className="ml-0 inline-block bg-info text-white text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
              <i className="fas fa-clock"></i>&nbsp;{passenger.pickupTime}
            </span>
          </div>
        )}
        {passenger.pickupStatus && (
          <span
            className={`ml-2 inline-block text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:text-green-800 ${getStatusClass(
              passenger.pickupStatus
            )}`}
          >
            {passenger.pickupStatus}
          </span>
        )}
      </div>
      <KebabMenu
        onSetTime={() => openTimeModal(passenger.id)}
        status={passenger.pickupStatus}
        onSetStatus={() => openStatusModal(passenger.id)}
      />
    </div>
  );
};

export default PassengerDetail;
