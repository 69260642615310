import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const KebabMenu = ({ onSetTime, onSetStatus, status }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="kebab-horizontal inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
        <i className="fas fa-ellipsis-v"></i>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/* Adjust the positioning of Menu.Items */}
        <Menu.Items className="origin-bottom-right absolute right-0 bottom-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  disabled={status === "Arrived"}
                  className={`${
                    active ? "bg-gray-100" : ""
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm ${
                    status === "Arrived"
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-gray-700"
                  }`}
                  onClick={onSetTime}
                >
                  Set Pickup Time
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-gray-100" : ""
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-700`}
                  onClick={onSetStatus}
                >
                  Set Status
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default KebabMenu;
