import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner"; // Make sure you have this component for loading indication
import useCRUD from "../../hooks/useCRUD";
import AddOrEditParticipantModal from "./AddorEditParticipantModal";

const DriversList = ({ eventId, event, eventParticipations }) => {
  const [drivers, setDrivers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: "availability",
    direction: "ascending",
  });

  const { addItem, updateItem } = useCRUD("/api/participant");

  const sortedDrivers = useMemo(() => {
    let sortableItems = [...drivers];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [drivers, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (eventParticipations) {
      //Filer to only show participants that are not drivers

      const filteredParticipants = eventParticipations.filter(
        (participant) => participant.isDriver
      );
      setDrivers(filteredParticipants);
    }
  }, [eventParticipations]);

  const handleAddNewDriverClick = () => {
    setIsModalOpen(true);
    setModalData(null); // Initialize data for new driver
  };

  const handleEditClick = (driver) => {
    setIsModalOpen(true);
    setModalData({ ...driver, isDriver: true }); // Set modal for editing a driver
  };

  const handleSave = async (data) => {
    try {
      setIsLoading(true);
      if (modalData) {
        // If modalData exists, we're editing an existing participant
        await updateItem(modalData.id, data);
      } else {
        // If modalData does not exist, we're adding a new participant
        await addItem(data);
      }
      console.log("Participant saved successfully:", data);
      // Optionally, refresh your participants list here
    } catch (error) {
      console.error("Error saving participant:", error);
    } finally {
      setIsModalOpen(false); // Close the modal after save
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spinner />;
  if (error) return <div>Error fetching drivers: {error}</div>;

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      {isLoading && <Spinner />}

      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {event?.pingSchedule
              ? "Driver Responses"
              : `${drivers?.length} Event Drivers`}
          </h3>
          {/* <button
            className="btn bg-[#004a7e] text-white mt-4 hover:bg-[#004a7e] hover:text-white btn-sm"
            onClick={handleAddNewDriverClick}
          >
            + Add New Driver
          </button> */}
        </div>

        {isModalOpen && (
          <AddOrEditParticipantModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={handleSave}
            initialData={modalData}
            isDriver={true}
            event={event}
          />
        )}

        {drivers.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Driver{" "}
                    {sortConfig.key === "name" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th className="hidden md:table-cell"> Phone</th>
                  <th onClick={() => requestSort("canTake")}>
                    Can Take{" "}
                    {sortConfig.key === "canTake" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th onClick={() => requestSort("availability")}>
                    Availability{" "}
                    {sortConfig.key === "availability" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th>Message To Admin</th>
                </tr>
              </thead>
              <tbody>
                {sortedDrivers.map((driver) => (
                  <tr key={driver.id}>
                    <td>
                      <div className="font-bold">
                        <a href={`/d/${driver.uniqueCode}`} target="_blank">
                          {driver.name}
                        </a>
                      </div>
                    </td>
                    <td className="hidden md:table-cell">
                      <div className="text-gray-500">{driver.phone}</div>
                    </td>
                    <td>{driver.canTake}</td>
                    <td>
                      <div
                        className={`badge ${
                          driver.availability === "Yes"
                            ? "badge-success text-white"
                            : driver.availability === "No"
                            ? "badge-error  text-white"
                            : "badge-warning  text-white"
                        }`}
                      >
                        {driver.availability}
                      </div>
                    </td>
                    <td>{driver.messageForAdmin}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No drivers to display.</p>
        )}
      </div>
    </div>
  );
};

export default DriversList;
