import "./disableConsole";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// Import Bugsnag and the React plugin
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

// Initialize Bugsnag
Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY, // Replace 'YOUR_API_KEY' with your actual Bugsnag API key
  plugins: [new BugsnagPluginReact(React)],
});

BugsnagPerformance.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  releaseStage: process.env.REACT_APP_NODE_ENV,
});

// Create a Bugsnag error boundary
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const root = ReactDOM.createRoot(document.getElementById("root"));
document.documentElement.setAttribute("data-theme", "mytheme");

// Use the Bugsnag error boundary to wrap your app
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
