import { useState, useCallback } from "react";

const useFileUpload = (endpoint) => {
  const apiBaseUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getToken = () => localStorage.getItem("userToken");

  // Function to upload a file
  const uploadFile = useCallback(
    async (file, additionalData = {}) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file);

      // Append any additional data as form fields
      Object.keys(additionalData).forEach((key) => {
        formData.append(key, additionalData[key]);
      });

      try {
        const response = await fetch(`${apiBaseUrl}${endpoint}`, {
          method: "POST",
          headers: {
            ...(getToken() ? { Authorization: `Bearer ${getToken()}` } : {}),
            Accept: "application/json",
            // Note: Don't set 'Content-Type' for FormData; the browser will set it correctly with the boundary
          },
          body: formData,
          credentials: "include", // If your API requires cookies/session
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message || "Upload failed");
        }

        const result = await response.json();
        return result; // Return the result of the upload
      } catch (err) {
        setError(err.message || "An error occurred during the upload.");
        return null; // Return null in case of an error
      } finally {
        setIsLoading(false);
      }
    },
    [apiBaseUrl, endpoint]
  );

  return {
    uploadFile,
    isLoading,
    error,
  };
};

export default useFileUpload;
