const Spinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div
      className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-[#004a7e]"
      style={{ borderTopColor: "transparent" }}
    ></div>
  </div>
);

export default Spinner;
