import React, { useState, useEffect } from "react";
import useCRUD from "../hooks/useCRUD";
import axios from "axios";
import { useHistory } from "react-router-dom";

const ChoosePlan = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");

  const { data: plans, fetchItems: fetchPlan } = useCRUD(
    "/api/subscription-plans"
  );

  const { data: user, fetchItems: fetchUser } = useCRUD("/api/user");

  const { data: checkoutUrl, addItem: createCheckoutSession } = useCRUD(
    "/api/create-checkout-session"
  );

  const history = useHistory();

  useEffect(() => {
    // Fetch plans from your API or define them here
    fetchPlan();
    fetchUser();
  }, [fetchPlan]);

  useEffect(() => {
    if (user?.account?.planName) {
      history.push("/");
    }
  });

  const handleBillingCycleChange = (cycle) => {
    setBillingCycle(cycle);
  };

  const handleChoosePlan = async (stripePriceId, planId) => {
    try {
      const data = await createCheckoutSession({ stripePriceId, planId });

      if (data) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error creating Stripe session:", error);
    }
  };

  const getWelcomeMessage = () => {
    if (user?.account?.isNewUser) {
      return "Select a plan to get started with My Event Ride.";
    }
    return "Your subscription has expired. Please select a plan to continue using My Event Ride.";
  };

  return (
    <div className="bg-white min-h-screen flex flex-col">
      <header className="bg-primary text-white text-center p-6">
        <h1 className="text-3xl font-semibold">
          Choose Your Plan for {user?.account?.name}
        </h1>
        <p className="mt-2 text-lg">{getWelcomeMessage()}</p>
      </header>

      <div className="mt-16 flex justify-center">
        <fieldset className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <legend className="sr-only">Payment frequency</legend>
          <label
            className={`cursor-pointer rounded-full px-2.5 py-1 ${
              billingCycle === "monthly"
                ? "bg-primary text-white"
                : "text-gray-500"
            }`}
          >
            <input
              type="radio"
              name="billing_cycle"
              value="monthly"
              className="sr-only"
              checked={billingCycle === "monthly"}
              onChange={() => handleBillingCycleChange("monthly")}
            />
            <span>Monthly</span>
          </label>
          <label
            className={`cursor-pointer rounded-full px-2.5 py-1 ${
              billingCycle === "yearly"
                ? "bg-primary text-white"
                : "text-gray-500"
            }`}
          >
            <input
              type="radio"
              name="billing_cycle"
              value="yearly"
              className="sr-only"
              checked={billingCycle === "yearly"}
              onChange={() => handleBillingCycleChange("yearly")}
            />
            <span>Annually</span>
          </label>
        </fieldset>
      </div>

      <div className="isolate mx-auto mt-10  pl-2 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        {plans?.map((plan) => (
          <div
            key={plan.id}
            className="rounded-3xl p-8 xl:p-10 ring-1 ring-gray-200"
          >
            {/* Plan details */}
            <h3 className="text-lg font-semibold leading-8 text-gray-900">
              {plan.name}
            </h3>
            <p className="mt-4 text-sm leading-6 text-gray-600">
              {plan.description}
            </p>
            <p className="mt-6 flex items-baseline gap-x-1">
              {billingCycle === "monthly" ? (
                plan.monthly_rate === "0.00" ? (
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    Free
                  </span>
                ) : (
                  <>
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      ${plan.monthly_rate}
                    </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      /monthly
                    </span>
                  </>
                )
              ) : plan.yearly_rate === "0.00" ? (
                <span className="text-4xl font-bold tracking-tight text-gray-900">
                  Free
                </span>
              ) : (
                <>
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    ${plan.yearly_rate}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /yearly
                  </span>
                </>
              )}
            </p>

            <button
              onClick={() =>
                handleChoosePlan(
                  billingCycle === "monthly"
                    ? plan.monthly_stripe_price_id
                    : plan.yearly_stripe_price_id,
                  plan.id
                )
              }
              className="mt-6 block  w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary text-primary ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300"
            >
              Choose plan
            </button>

            <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
              {JSON.parse(plan.features).map((feature, index) => (
                <li key={`${plan.id}-${index}`} className="flex gap-x-3">
                  {" "}
                  {/* Ensure key is unique */}
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-primary"
                    fill="none"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <footer className="bg-primary text-white text-center p-4 mt-auto">
        My Event Ride © {new Date().getFullYear()} All rights reserved.
      </footer>
    </div>
  );
};

export default ChoosePlan;
