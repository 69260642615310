import React, { useState, useEffect } from "react";

const VersionHistory = () => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    fetch("/version-history.json")
      .then((response) => response.json())
      .then((data) => setVersions(data));
  }, []);

  return (
    <div className="version-history m-8">
      <h1 className="text-3xl font-bold text-center mb-6">Version History</h1>
      {versions.map((version) => (
        <div
          key={version.version}
          className="mb-8 p-4 shadow-lg rounded-lg bg-white"
        >
          <h2 className="text-2xl font-semibold mb-2">
            v{version.version} - {version.date}
          </h2>
          {version.newFeatures && version.newFeatures.length > 0 && (
            <div>
              <p className="font-bold">New Features:</p>
              <ul className="list-disc list-inside mb-4">
                {version.newFeatures.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
          )}
          {version.enhancements && version.enhancements.length > 0 && (
            <div>
              <p className="font-bold">Enhancements:</p>
              <ul className="list-disc list-inside mb-4">
                {version.enhancements.map((enhancement, index) => (
                  <li key={index}>{enhancement}</li>
                ))}
              </ul>
            </div>
          )}
          {version.bugFixes && version.bugFixes.length > 0 && (
            <div>
              <p className="font-bold">Bug Fixes:</p>
              <ul className="list-disc list-inside mb-4">
                {version.bugFixes.map((bug, index) => (
                  <li key={index}>{bug}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default VersionHistory;
