import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import useCRUD from "../hooks/useCRUD";
import Spinner from "../components/Spinner/Spinner";

const SubscriptionComplete = () => {
  const history = useHistory();
  const { updateAuthState } = useAuth();
  const { data: user, fetchData } = useCRUD("/api/user");

  // State to hold the plan name
  const [planName, setPlanName] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      await fetchData();
      if (user && user.data?.account) {
        setPlanName(user.data.account.planName);
        updateAuthState(user.data.account.planName);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (planName) {
      const redirectTimeout = setTimeout(() => {
        history.push("/");
      }, 3000);

      return () => clearTimeout(redirectTimeout);
    }
  }, [planName]);

  if (!planName) {
    return <Spinner />;
  }
  return (
    <>
      <div className="bg-primary text-white p-4 rounded-lg shadow-lg">
        <h1 className="text-xl md:text-2xl font-bold">
          Welcome, {user.data.name}!
        </h1>
      </div>
      <div className="card bg-white shadow-lg max-w-4xl mx-auto p-6 rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          Subscription Complete
        </h2>
        <p className="text-gray-600">
          Thank you for subscribing to our <strong>{planName}</strong> Plan. You
          are now being redirected to the homepage.
        </p>
      </div>
    </>
  );
};

export default SubscriptionComplete;
