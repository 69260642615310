import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import { useParams, useHistory } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import { formatDateShort } from "../../utils/dateUtils";

const EditEvent = () => {
  const { id } = useParams();

  const { data: event, fetchItems: fetchEvent } = useCRUD("/api/events/" + id);

  const { updateItem: updateEvent } = useCRUD("/api/events");

  const { data: groups, fetchItems: getGroups } = useCRUD(
    "/api/participant-groups"
  );

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toast, setToast] = useState(false); // New state for managing toast visibility

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent, id]);

  useEffect(() => {
    getGroups();
  }, [getGroups]);

  const handleDateTimeChange = (date, fieldName) => {
    // Convert the date to UTC and format it for submission
    const utcDateString = moment(date).utc().format();

    setFormData({
      ...form,
      [fieldName]: utcDateString,
    });
  };

  const showSuccessToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false); // Hide toast after 3 seconds
      history.push(`/event/manage/${id}`); // Redirect after toast is hidden
    }, 3000);
  };

  const usTimeZones = [
    { label: "Eastern Time (ET)", value: "America/New_York" },
    { label: "Central Time (CT)", value: "America/Chicago" },
    { label: "Mountain Time (MT)", value: "America/Denver" },
    { label: "Mountain Time (MT) no DST", value: "America/Phoenix" },
    { label: "Pacific Time (PT)", value: "America/Los_Angeles" },
    { label: "Alaska Time (AKT)", value: "America/Anchorage" },
    { label: "Hawaii Time (HT)", value: "Pacific/Honolulu" },
  ];

  const [form, setFormData] = useState({
    title: "",
    description: "",
    location: "",
    dateOfOccurrence: "",

    pingParticipants: false,
    pingDrivers: false,
    pingSchedule: "",

    eventMessageForDrivers: "",
    eventTimeZone: "",
    participantGroupId: "",
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...form, [e.target.name]: value });
  };

  const [isEventExpired, setIsEventExpired] = useState(false);

  useEffect(() => {
    if (event?.dateOfOccurrence) {
      const eventDate = moment(event.dateOfOccurrence);
      const now = moment();
      setIsEventExpired(eventDate.isBefore(now)); // Set to true if the event date is in the past
    }
  }, [event]);

  useEffect(() => {
    if (event) {
      // Format dateOfOccurrence and endDate for input compatibility
      // Convert UTC string to Date object

      //USe moment to adjust to UTC
      const formattedDateOfOccurrence = event.dateOfOccurrence
        ? moment.utc(event.dateOfOccurrence).local().format("YYYY-MM-DDTHH:mm")
        : "";

      const formattedPingSchedule = event.pingSchedule
        ? moment.utc(event.pingSchedule).local().format("YYYY-MM-DDTHH:mm")
        : "";

      setFormData({
        title: event.title,
        description: event.description,
        location: event.location,
        dateOfOccurrence: formattedDateOfOccurrence,

        pingParticipants: event.pingParticipants || false,
        pingDrivers: event.pingDrivers || false,
        pingSchedule: formattedPingSchedule,

        eventMessageForDrivers: event.eventMessageForDrivers,
        eventTimeZone: event.eventTimeZone,
        participantGroupId: event.participantGroup,
      });
    }
  }, [event]);

  const validateForm = () => {
    // Basic validation logic here
    // Return false if validation fails, true otherwise
    if (!form.title.trim()) {
      setError("Event Name is required.");
      return false;
    }
    if (!form.dateOfOccurrence) {
      setError("Event Date is required.");
      return false;
    }

    if ((form.pingParticipants || form.pingDrivers) && !form.pingSchedule) {
      setError("Reminder Dateis required when sending reminders.");
      return false;
    }
    if (new Date(form.pingSchedule) >= new Date(form.dateOfOccurrence)) {
      setError("Reminder Date must be before the Date of Event");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setIsLoading(true);
    setError(""); // Reset error message

    const submissionData = {
      ...form,
      dateOfOccurrence: moment(form.dateOfOccurrence).utc().format(),
      pingSchedule: form.pingSchedule
        ? moment(form.pingSchedule).utc().format()
        : "",
    };

    try {
      await updateEvent(id, submissionData);
      showSuccessToast(); // Show toast and redirect on success
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Configure SMS Templates for {event?.title} -{" "}
          {formatDateShort(event?.dateOfOccurrence)}
        </h2>
        <button
          onClick={() => history.push(`/event/manage/${id}`)}
          className="bg-primary hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
        >
          Manage Event
        </button>
      </div>
      {toast && (
        <div className="fixed top-20 right-20 bg-green-500 text-white p-3 rounded">
          Changes saved successfully!
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="space-y-6 bg-white shadow-sm rounded-md p-6"
      >
        {/* Basic Event Details Section */}
        <div className="space-y-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Basic Event Details
          </h3>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Event Name
              </label>
              <input
                id="title"
                name="title"
                type="text"
                value={form.title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location
              </label>
              <input
                id="location"
                name="location"
                type="text"
                value={form.location}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={form.description}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="dateOfOccurrence"
                className="block text-sm font-medium text-gray-700"
              >
                Event Date
              </label>

              <DatePicker
                selected={
                  form.dateOfOccurrence ? new Date(form.dateOfOccurrence) : ""
                }
                onChange={(date) =>
                  handleDateTimeChange(date, "dateOfOccurrence")
                }
                showTimeSelect
                timeFormat="h:mm aa"
                timeIntervals={15}
                minDate={new Date()}
                name="dateOfOccurrence"
                timeCaption="time"
                dateFormat="yyyy-MM-dd h:mm aa"
                className="mt-1 w-full block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="eventTimeZone"
                className="block text-sm font-medium text-gray-700"
              >
                Event Timezone
              </label>
              <select
                id="eventTimeZone"
                name="eventTimeZone"
                value={form.eventTimeZone}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {usTimeZones.map((zone) => (
                  <option key={zone.value} value={zone.value}>
                    {zone.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Reminder Settings Section */}
        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              Reminder Settings
            </legend>
            <div className="mt-4 space-y-4">
              {/* Reminder configurations similar to the above sections */}

              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id="pingParticipants"
                    name="pingParticipants"
                    checked={form.pingParticipants}
                    onChange={handleChange}
                  />
                  <span className="ml-2">
                    Send Reminders to Participants for availability before
                    event?
                  </span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id="pingDrivers"
                    name="pingDrivers"
                    checked={form.pingDrivers}
                    onChange={handleChange}
                  />
                  <span className="ml-2">
                    Send Reminders to Drivers for availability before event?
                  </span>
                </label>
              </div>
              {(form.pingParticipants || form.pingDrivers) && (
                <div>
                  <label
                    htmlFor="pingSchedule"
                    className="block text-sm font-medium text-gray-700"
                  >
                    When should reminders go out?
                  </label>

                  <DatePicker
                    selected={
                      form.pingSchedule ? new Date(form.pingSchedule) : ""
                    }
                    onChange={(date) =>
                      handleDateTimeChange(date, "pingSchedule")
                    }
                    showTimeSelect
                    timeFormat="h:mm aa"
                    name="pingSchedule"
                    timeIntervals={60}
                    minDate={new Date()}
                    timeCaption="time"
                    dateFormat="yyyy-MM-dd h:mm aa"
                    className="mt-1 w-full block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              )}
            </div>
          </fieldset>
        </div>

        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              Event Group
            </legend>
            <div className="mt-4 space-y-4">
              {/* Reminder configurations similar to the above sections */}
              <div>
                <label
                  htmlFor="participantGroupId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Participant Group
                </label>
                <select
                  id="participantGroupId"
                  name="participantGroupId"
                  value={form.participantGroupId}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {groups?.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.groupName} - {group.noOfParticipants} Members
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </fieldset>
        </div>

        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              Misc Settings
            </legend>
            <div className="mt-4 space-y-4">
              <div>
                <textarea
                  className="border p-2 rounded w-full mt-1"
                  id="eventMessageForDrivers"
                  name="eventMessageForDrivers"
                  rows="4"
                  placeholder="Message/Notes for Event Drivers"
                  value={form.eventMessageForDrivers}
                  onChange={handleChange}
                ></textarea>
                <p className="text-sm mb-4 bg-blue-200">
                  Message or instructions for drivers. This will appear on their
                  pickup page.
                </p>
              </div>
            </div>
          </fieldset>
        </div>

        {error && <div className="text-red-500 font-bold">{error}</div>}
        {!isEventExpired && (
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isLoading || isEventExpired} // Also disable the button if the event has expired
                className="w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {isLoading ? "Saving..." : "Update Event"}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditEvent;
