import React, { useState } from "react";

const statuses = ["On-Route", "Arrived"];

const StatusModal = ({ isOpen, onClose, onSave }) => {
  const [status, setStatus] = useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-4">
        <h2 className="text-lg font-bold mb-4">Set Status</h2>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="border-2 border-gray-300 rounded p-2 mb-4 w-full"
        >
          <option value="">Select a status</option>
          {statuses.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </select>
        <div className="flex justify-end space-x-4">
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => onSave(status)}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
