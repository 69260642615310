// EventCard.js
import React, { useState, useEffect, useRef } from "react";
import { formatDateWithDay } from "../../utils/dateUtils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faEdit,
  faEye,
  faCalendarAlt,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";

const EventCard = ({
  title,
  dateOfOccurrence,
  location,
  id,
  onManageClick,
  onDeleteClick,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const buttonRef = useRef(null); // Correct reference for the button that toggles the menu

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuRef]);

  return (
    <div className="card w-full md:w-96 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-5 flex flex-col">
        <div className="flex items-center flex-wrap">
          {" "}
          {/* Enable wrapping */}
          <FontAwesomeIcon
            icon={faCalendarAlt}
            size="2x"
            className="text-primary mr-3"
          />
          <div className="flex-1 min-w-0">
            {" "}
            {/* Allow shrink and ensure minimum width */}
            <h2 className="text-xl font-semibold text-gray-800 truncate">
              {title}
            </h2>{" "}
            {/* Truncate longer text */}
            <p className="text-sm text-gray-500 truncate">{location}</p>{" "}
            {/* Truncate longer text */}
          </div>
          <button
            ref={buttonRef}
            onClick={toggleMenu}
            className="text-gray-600 hover:text-gray-800 ml-auto"
          >
            <FontAwesomeIcon icon={faEdit} size="lg" />
          </button>
        </div>
        <div className="mt-2">
          <button className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <FontAwesomeIcon
              icon={faCalendar}
              className="mr-2 h-5 w-5 text-green-600"
            />
            {formatDateWithDay(dateOfOccurrence)}
          </button>
        </div>
        {showMenu && (
          <div
            ref={menuRef}
            className="absolute right-0 mt-10 w-48 bg-white rounded-md shadow-xl z-20 border-1 border-gray-200"
          >
            <ul className="list-none py-1 text-gray-700">
              <li>
                <Link
                  to={`/event/edit/${id}`}
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Edit
                </Link>
              </li>
              <li>
                <button
                  onClick={() => onDeleteClick(id)}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        )}
        <div className="mt-4">
          <Link
            to={`/event/manage/${id}`}
            className="text-sm bg-primary text-white py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" /> Manage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
