import React, { useState, useEffect } from "react";

const Autocomplete = ({ options, onSelect, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      const filtered = options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
      setIsOptionsVisible(true);
    } else {
      setFilteredOptions([]);
      setIsOptionsVisible(false);
    }
  }, [options, searchTerm]);

  return (
    <div className="autocomplete">
      <input
        type="text"
        className="autocomplete-input"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsOptionsVisible(true)}
        onBlur={() => setTimeout(() => setIsOptionsVisible(false), 100)}
      />
      {isOptionsVisible && filteredOptions.length > 0 && (
        <ul className="autocomplete-options">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="autocomplete-option"
              onClick={() => {
                onSelect(option);
                setSearchTerm("");
                setIsOptionsVisible(false);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
