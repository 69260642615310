import React, { useState, useEffect } from "react";

// Assuming useCRUD is a custom hook for handling CRUD operations with your backend
import useCRUD from "../hooks/useCRUD";
import AddOrEditUserModal from "../components/Account/AddOrEditUserModal";
import ConfirmModal from "../components/Elements/ConfirmModal";

import { formatDateDayMonthYear } from "../utils/dateUtils";

const Settings = () => {
  // State to store the fetched account details
  const [error, setError] = useState(null);
  const [logo, setLogo] = useState(null); // For storing the selected logo file

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editUserData, setEditUserData] = useState(null); // For editing, pass initial data to modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [showDeleteLogoConfirmModal, setShowDeleteLogoConfirmModal] =
    useState(false);

  const [showEditLogoModal, setShowEditLogoModal] = useState(false);

  const { updateItem, deleteItem } = useCRUD("/api/account/user");
  const { addItem: deleteLogo } = useCRUD("/api/account/delete-logo");
  const { addItem: addUser } = useCRUD("/api/account/create-user");
  const { data: userDetails, fetchItems: fetchUser } = useCRUD("/api/user");

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleOpenModal = (userData = null) => {
    setEditUserData(userData); // If userData is provided, we're editing, otherwise adding
    setIsModalOpen(true);
  };

  const handleSaveUser = async (formData) => {
    if (formData.id) await updateItem(formData.id, formData);
    else await addUser(formData);

    handleCloseModal();
    fetchAccountDetails();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditUserData(null); // Clear any edit data on close
  };

  const handleVerifyUser = async (userId) => {
    await updateItem(userId, { isVerified: true });

    fetchAccountDetails();
  };

  const handleEditLogo = () => {
    setShowEditLogoModal(true);
  };

  // Assuming useCRUD provides functionality to fetch and update data
  const {
    data: accountDetails,
    fetchItems: fetchAccountDetails,
    addLogo,
  } = useCRUD("/api/account");

  const { addItem: createBillingSession } = useCRUD(
    "/api/account/billing-portal"
  );

  useEffect(() => {
    fetchAccountDetails();
  }, [fetchAccountDetails]);

  const handleDeleteEvent = async (id) => {
    setShowConfirmModal(false);
    try {
      await deleteItem(id);
      fetchAccountDetails();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const promptDeleteConfirmation = (id) => {
    setEventToDelete(id);
    setShowConfirmModal(true);
  };

  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleDeleteLogo = () => {
    setShowDeleteLogoConfirmModal(true);
  };

  const handleChangePlanClick = async () => {
    try {
      const data = await createBillingSession();

      if (data) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error("Error creating billing session:", error);
    }
  };

  const confirmDeleteLogo = async () => {
    try {
      // Assuming deleteLogo is a method from useCRUD to call the delete logo endpoint
      const response = await deleteLogo(); // Adjust the endpoint/method as needed
      if (response) alert("Logo deleted successfully.");
      fetchAccountDetails(); // Refresh account details to update the UI
    } catch (error) {
      console.error("Error deleting logo:", error);
      setError("Failed to delete logo.");
    }
    setShowDeleteLogoConfirmModal(false); // Close the confirmation modal
  };

  const handleLogoUpload = async () => {
    if (!logo) {
      setError("Please select a logo to upload.");
      return;
    }
    const formData = new FormData();
    formData.append("logo", logo);

    try {
      await addLogo(formData); // Adjust API endpoint/method as needed
      alert("Logo uploaded successfully.");
      // Fetch account details again to update the logo in the UI
      fetchAccountDetails();
      setShowEditLogoModal(false);
    } catch (error) {
      console.error("Error uploading logo:", error);
      setError("Failed to upload logo.");
    }
  };

  if (!accountDetails) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  if (!accountDetails) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  // Render account plan section
  const renderAccountPlanSection = () => {
    if (accountDetails.planName === "Legacy") {
      return (
        <div className="bg-white shadow overflow-hidden rounded-md p-4 mb-8">
          <h3 className="text-lg font-semibold mb-2">Account Plan</h3>
          <p>
            Your account is on the{" "}
            <span className="inline-block  text-center  bg-purple-500 text-white py-1 px-3 ">
              {accountDetails.planName}
            </span>
            &nbsp; plan - You have free lifetime access to all our features.
          </p>
        </div>
      );
    } else {
      return (
        <div className="bg-white shadow overflow-hidden rounded-md p-4 mb-8">
          <h3 className="text-lg font-semibold mb-2">Account Plan</h3>
          <p>
            Your account is on the <strong>{accountDetails.planName}</strong>{" "}
            plan. Next billing date:{" "}
            <strong>
              {formatDateDayMonthYear(accountDetails.nextBillingDate)}
            </strong>
          </p>
          {accountDetails.adminInfo === userDetails?.email && (
            <button
              onClick={handleChangePlanClick}
              className="btn btn-md bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded mt-2"
            >
              Manage Plan
            </button>
          )}
        </div>
      );
    }
  };

  // Render logo section based on whether a logo exists
  const renderLogoSection = () => {
    if (accountDetails.logo) {
      return (
        <div className="logo-section mb-8 p-4 bg-white rounded-lg shadow relative">
          <h3 className="text-lg font-semibold mb-2">Account Logo</h3>
          <img
            src={accountDetails.logo}
            alt="Account Logo"
            className="mb-2"
            style={{
              maxWidth: "150px",
              maxHeight: "150px",
              objectFit: "contain",
            }}
          />
          <div className="absolute top-2 right-2 flex">
            <span
              className="cursor-pointer text-gray-600 hover:text-gray-800 p-2"
              onClick={() => setShowEditLogoModal(true)}
              title="Edit logo"
            >
              <i className="fas fa-edit"></i>
            </span>
            <span
              className="cursor-pointer text-gray-600 hover:text-red-600 p-2"
              onClick={() => setShowDeleteLogoConfirmModal(true)}
              title="Delete logo"
            >
              <i className="fas fa-trash-alt"></i>
            </span>
          </div>
          {showEditLogoModal && (
            <div>
              <input
                type="file"
                onChange={handleLogoChange}
                className="form-input"
                accept="image/*"
              />
              <button
                className="btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mt-2"
                onClick={handleLogoUpload}
              >
                Upload Logo
              </button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className="logo-upload-section mb-8 p-4 bg-white rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2">Upload Account Logo</h3>
          <input
            type="file"
            onChange={handleLogoChange}
            className="form-input"
            accept="image/*"
            maxLength={2 * 1024 * 1024} // Max 2MB
          />
          <button
            className="btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mt-2"
            onClick={handleLogoUpload}
          >
            Upload Logo
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-8">
        {showConfirmModal && (
          <ConfirmModal
            isOpen={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={() => handleDeleteEvent(eventToDelete)}
            message="Are you sure you want to delete this user?"
          />
        )}

        {showDeleteLogoConfirmModal && (
          <ConfirmModal
            isOpen={showDeleteLogoConfirmModal}
            onClose={() => setShowDeleteLogoConfirmModal(false)}
            onConfirm={confirmDeleteLogo}
            message="Are you sure you want to delete the account logo?"
          />
        )}

        <AddOrEditUserModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveUser}
          initialData={editUserData}
        />
        <h1 className="text-2xl font-semibold">
          {accountDetails.name}'s Account Settings
        </h1>
      </div>
      {/* Account Plan Section */}
      {renderAccountPlanSection()}
      {/* Logo upload section */}
      {/* Logo upload section with improved styling */}
      {/* <div className="logo-upload-section mb-8 p-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Account Logo</h3>
        {accountDetails.logo && (
          <img
            src={accountDetails.logo}
            alt="Account Logo"
            className="mb-2 max-w-xs"
          />
        )}
        <div className="flex items-center space-x-4">
          <input
            type="file"
            onChange={handleLogoChange}
            className="form-input"
            accept="image/*"
            maxLength={2 * 1024 * 1024} // Max 2MB
          />
          <span className="text-gray-500 text-sm">(max 2MB)</span>
          <button
            className="btn bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
            onClick={handleLogoUpload}
          >
            Upload Logo
          </button>
        </div>
      </div> */}

      {renderLogoSection()}

      <div className="bg-white shadow overflow-hidden rounded-md">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {accountDetails.noOfUsers} Users
            </h3>
            <button
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              onClick={() => handleOpenModal(null)}
            >
              <svg
                className="-ml-1 mr-2 h-5 w-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Add User
            </button>
          </div>
          <p className="text-sm text-gray-500">
            Invite users to manage rides for your account. Users can have
            limited access to manage rides or be assigned admin privileges
            across your whole account.
          </p>
        </div>

        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Role
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {accountDetails.users.map((user) => (
              <tr key={user.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {user.name} <br />
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.email}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {!user.isVerified && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      Pending
                    </span>
                  )}
                  {user.isVerified && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      Active
                    </span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {user.isAdmin ? "Admin" : "User"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {accountDetails.adminInfo !== user.email ? (
                    <>
                      <button
                        className="btn bg-[#004a7e] hover:bg-[#00385b] text-white py-2 px-4 rounded focus:ring-[#004a7e] focus:ring-opacity-50 focus:outline-none focus:ring focus:ring-offset-2 transition ease-in duration-200 text-xs"
                        onClick={() => handleOpenModal(user)}
                      >
                        Edit
                      </button>
                      &nbsp;
                      <button
                        className="btn bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded focus:ring-red-500 focus:ring-opacity-50 focus:outline-none focus:ring focus:ring-offset-2 transition ease-in duration-200 text-xs"
                        onClick={() => promptDeleteConfirmation(user.id)}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <span className="text-gray-500 italic">
                      Can't Modify Main Admin
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Settings;
