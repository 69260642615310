import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import TemplateField from "../Elements/TemplateField";
import { formatDateShort } from "../../utils/dateUtils";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import { useParams, useHistory } from "react-router-dom";

const SmsTemplateForm = () => {
  const { id } = useParams();

  const {
    data: event,

    fetchItems: fetchEvent,
  } = useCRUD("/api/events/" + id);

  const { updateItem: updateEvent } = useCRUD("/api/events");

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toast, setToast] = useState(false); // New state for managing toast visibility

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent, id]);

  const templateVariables = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{link}}",
  ];

  const templateVariablesForDrivers = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{driverName}}",
    "{{driverPhone}}",
  ];

  const [form, setFormData] = useState({
    participantTemplate: "",
    driverTemplate: "",
    riderAssignment: "",
    driverAssignment: "",
    pingDrivers: "",
    pingParticipants: "",
  });

  const [isEventExpired, setIsEventExpired] = useState(false);

  useEffect(() => {
    if (event?.dateOfOccurrence) {
      const eventDate = moment(event.dateOfOccurrence);
      const now = moment();
      setIsEventExpired(eventDate.isBefore(now)); // Set to true if the event date is in the past
    }
  }, [event]);

  const showSuccessToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false); // Hide toast after 3 seconds
      history.push(`/event/manage/${id}`); // Redirect after toast is hidden
    }, 3000);
  };
  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...form, [e.target.name]: value });
  };

  const validateForm = () => {
    // Basic validation logic here
    // Return false if validation fails, true otherwise

    if (
      form.pingParticipants &&
      !form.participantTemplate.includes("{{link}}")
    ) {
      setError("Participant RSVP SMS Template must include {{link}}.");
      return false;
    }
    if (form.pingDrivers && !form.driverTemplate.includes("{{link}}")) {
      setError("Driver RSVP SMS Template must include {{link}}.");
      return false;
    }

    if (
      form.riderAssignment &&
      !form.riderAssignment.includes("{{driverName}}")
    ) {
      setError("Rider Assignment Template must include {{driverName}}.");
      return false;
    }
    if (form.driverAssignment && !form.driverAssignment.includes("{{link}}")) {
      setError("Driver Assignment Template must include {{link}}.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setIsLoading(true);
    setError(""); // Reset error message

    try {
      await updateEvent(id, form);
      showSuccessToast(); // Show toast and redirect on success
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (event) {
      setFormData({
        participantTemplate: event.participantTemplate
          ? event.participantTemplate
          : "",
        driverTemplate: event.driverTemplate ? event.driverTemplate : "",
        driverAssignment: event.driverAssignment ? event.driverAssignment : "",
        riderAssignment: event.riderAssignment ? event.riderAssignment : "",
        pingDrivers: event.pingDrivers ? event.pingDrivers : "",
        pingParticipants: event.pingParticipants ? event.pingParticipants : "",
      });
    }
  }, [event]);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Configure SMS Templates for {event?.title} -{" "}
          {formatDateShort(event?.dateOfOccurrence)}
        </h2>
        <button
          onClick={() => history.push(`/event/manage/${id}`)}
          className="bg-primary hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
        >
          Manage Event
        </button>
      </div>
      {toast && (
        <div className="fixed top-20 right-20 bg-green-500 text-white p-3 rounded">
          Changes saved successfully!
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        className="space-y-6 bg-white shadow-sm rounded-md p-6"
      >
        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              SMS Template Configuration
            </legend>
            <p className="text-sm my-4 p-2 rounded">
              Add Dynamic variables to your template by selecting the variables
              you want to use in your template and clicking Insert. If sms
              template is not configured, default template will be used.
            </p>

            <div className="mt-4 space-y-4">
              {form.pingParticipants && (
                <div>
                  <TemplateField
                    id="participantTemplate"
                    name="participantTemplate"
                    label="Participant  RSVP SMS Template"
                    tooltip="Message to be sent to participants to see if they need a ride. Must include {{link}} variable"
                    placeholder="Hi,{{name}}, {{eventName}} this {{eventDate}}. Confirm if you need a ride here: {{link}} (no-reply)"
                    templateVariables={templateVariables}
                    value={form.participantTemplate}
                    onChange={handleChange}
                  />
                </div>
              )}
              {form.pingDrivers && (
                <div>
                  <TemplateField
                    id="driverTemplate"
                    name="driverTemplate"
                    label="Driver RSVP SMS Template"
                    tooltip="Message to be sent to drivers to see if they can provide rides. Must include {{link}} variable"
                    placeholder="Hi {{name}}, can you provide rides for {{eventName}} on {{eventDate}}? Reply here: {{link}} (no-reply)"
                    templateVariables={templateVariables}
                    value={form.driverTemplate}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div>
                <TemplateField
                  id="riderAssignment"
                  name="riderAssignment"
                  label="Rider Assignment SMS Template."
                  tooltip="Message to send to the participant informing them who will pick them up.Must include {{driverName}} variable"
                  placeholder="Hi {{name}}, {{driverName}} will pick up for {{eventName}} on {{eventDate}}."
                  templateVariables={templateVariablesForDrivers}
                  value={form.riderAssignment}
                  onChange={handleChange}
                />
              </div>

              <div>
                <TemplateField
                  id="driverAssignment"
                  name="driverAssignment"
                  label="Driver Assignment SMS Template."
                  tooltip="Message to sent to driver informing them of their pickup list. Must include {{link}} variable"
                  placeholder="Hi {{name}}, Here is your pickup list for {{eventName}} on {{eventDate}}. Click here: {{link}} (no-reply)"
                  templateVariables={templateVariables}
                  value={form.driverAssignment}
                  onChange={handleChange}
                />
              </div>
            </div>
          </fieldset>
        </div>
        {error && <div className="text-red-500 font-bold">{error}</div>}
        {!isEventExpired && (
          <div className="pt-5">
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isLoading || isEventExpired} // Also disable the button if the event has expired
                className="w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {isLoading ? "Saving..." : "Save Sms Templates"}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default SmsTemplateForm;
