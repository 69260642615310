import React, { createContext, useContext, useState, useEffect } from "react";
import { isAuthenticated, isAdmin } from "../utils/auth";

import useCRUD from "../hooks/useCRUD";
import { set } from "lodash";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [isAdminUser, setIsAdminUser] = useState(isAdmin());
  const [userPlan, setUserPlan] = useState(null);

  const { data: user, fetchData: fetchUser } = useCRUD("/api/user");

  const [isLoading, setIsLoading] = useState();

  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem("userTokenExpiration");
    if (tokenExpiration) {
      const expirationTime =
        new Date(parseFloat(tokenExpiration) * 1000) - new Date();
      if (expirationTime > 0) {
        // Token is still valid, set a timeout to update auth state when it expires
        setTimeout(() => {
          updateAuthState();
          // Token expired, update the state and potentially prompt for re-login or log out.
        }, expirationTime);
      } else {
        // Token is already expired, update the auth state
        updateAuthState();
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      console.log("Fetching user profile...");
      fetchUser();
    }
  }, [isLoggedIn, fetchUser]);

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      setUserPlan(user?.data?.account?.planName);
      setIsLoading(false);
    }
  }, [user]); // This effect runs whenever `user` changes.

  const updateAuthState = (planName = null) => {
    console.log("Updating auth state");
    setIsLoggedIn(isAuthenticated());
    setIsAdminUser(isAdmin());

    if (planName) {
      setUserPlan(planName);
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, isAdminUser, userPlan, updateAuthState, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
