import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import { formatDateWithDay } from "../utils/dateUtils";
import Spinner from "../components/Spinner/Spinner";
import PassengerDetail from "../components/Driver/PassengerDetail";
import TimeModal from "../components/Elements/TimeModal";
import StatusModal from "../components/Elements/StatusModal";

const DriverAssignmentList = () => {
  const { uniqueCode } = useParams();

  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const {
    data: rideList,
    fetchItems: fetchRideList,
    isLoading,
    error,
  } = useCRUD(`/api/event-participations/assigned-driver-list/${uniqueCode}`);

  const { updateItem: updateConfirmation } = useCRUD(
    `/api/event-participations/confirm-ride`
  );

  const { updateItem: updatePickup } = useCRUD(
    `/api/event-participations/update-sms-status`
  );
  const [editingPickup, setEditingPickup] = useState(null);

  const onSetTime = async (time) => {
    const updatePayload = { pickupTime: time };
    const response = await updatePickup(editingPickup, updatePayload);
    if (response) {
      alert("Pickup time updated and notification sent to passenger");
      fetchRideList();
      setShowTimeModal(false);
      setEditingPickup(null);
    }
  };

  const onSetStatus = async (status) => {
    const updatePayload = { pickupStatus: status };
    const response = await updatePickup(editingPickup, updatePayload);
    if (response) {
      alert("Pickup status updated and notification sent to passenger");
      fetchRideList();
      setShowStatusModal(false);
      setEditingPickup(null);
    }
  };

  //Function to open time modal
  const openTimeModal = (pickupId) => {
    setEditingPickup(pickupId);
    console.log("pickupId", pickupId);
    setShowTimeModal(true);
  };

  // Function to open status modal
  const openStatusModal = (pickupId) => {
    setEditingPickup(pickupId);
    setShowStatusModal(true);
  };

  const handleSendConfirmation = async () => {
    const updatePayload = { confirmedPickupAssignment: true };
    const response = await updateConfirmation(
      rideList.driverDetails.uniqueCode,
      updatePayload
    );
    if (response) {
      alert("Notification sent");
      fetchRideList();
    }
  };

  useEffect(() => {
    fetchRideList();
  }, [fetchRideList]);

  if (isLoading) return <Spinner />;
  if (error)
    return <div className="text-center text-red-500">Invalid Request</div>;
  if (!rideList)
    return <div className="text-center text-red-500">No Rides Found</div>;
  if (!rideList.isDriver)
    return <div className="text-center text-red-500">Unauthorized</div>;

  const {
    driverDetails,
    passengers,
    eventDetails,
    messageFromAdmin,
    accountLogo,
  } = rideList;
  const sortedPassengers = passengers.sort((a, b) => {
    // Check if both passengers have a pickup time
    if (a.pickupTime && b.pickupTime) {
      // Convert time to a comparable number (minutes from start of the day)
      const getTimeInMinutes = (time) => {
        const [hour, minutePart] = time.split(":");
        const minutes = parseInt(minutePart.split(" ")[0], 10);
        const isPM = minutePart.split(" ")[1] === "PM";
        return (
          parseInt(hour, 10) * 60 +
          minutes +
          (isPM && hour !== "12" ? 12 * 60 : 0) -
          (hour === "12" && !isPM ? 12 * 60 : 0)
        );
      };

      const aTimeInMinutes = getTimeInMinutes(a.pickupTime);
      const bTimeInMinutes = getTimeInMinutes(b.pickupTime);

      return aTimeInMinutes - bTimeInMinutes;
    }

    // If only one of the passengers has a pickup time, prioritize it
    if (a.pickupTime) return -1;
    if (b.pickupTime) return 1;

    // If neither has a pickup time, sort by rideLocation
    return a.rideLocation.localeCompare(b.rideLocation);
  });

  const currentDate = new Date();
  const eventDate = new Date(eventDetails.dateOfOccurrence);

  // Add 12 hours to eventDate in UTC
  eventDate.setUTCHours(eventDate.getUTCHours() + 12);

  // If you want to compare the current date in UTC as well
  // Convert currentDate to UTC by creating a new Date object in UTC
  const currentUTCDate = new Date(
    Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    )
  );

  const totalPeople = sortedPassengers?.reduce(
    (acc, passenger) => acc + passenger.noOfPpl,
    0
  );

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-no-repeat bg-cover bg-center"
      style={{ backgroundImage: "url('/background.png')" }}
    >
      <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow-lg">
        {/* Display accountLogo if present */}
        {accountLogo && (
          <div className="text-center mb-4">
            <img
              src={accountLogo}
              alt="Account Logo"
              className="mx-auto"
              style={{ maxWidth: "150px", maxHeight: "150px" }} // Limiting size
            />
          </div>
        )}
        <h1 className="text-2xl font-bold mb-4">Hi, {driverDetails.name}</h1>
        {eventDetails.eventMessageForDrivers && (
          <div className="bg-blue-100 p-3 rounded-lg mb-4">
            <h2 className="text-lg font-semibold">
              Event Message for Drivers:
            </h2>
            <p>{eventDetails.eventMessageForDrivers}</p>
          </div>
        )}

        {/* Displaying message from admin */}
        {eventDate < currentUTCDate ? (
          <div className="text-center text-red-500 p-4">Event expired.</div>
        ) : (
          <>
            <p className="mb-2">
              {sortedPassengers.length > 0 ? (
                <>
                  Below is your list of{" "}
                  <span className="font-bold text-success">{totalPeople} </span>
                  pickups for <strong>{eventDetails.title}</strong> starting at{" "}
                  <strong>
                    {formatDateWithDay(eventDetails.dateOfOccurrence)}
                  </strong>
                </>
              ) : (
                "You don't have any pickups assigned. Thank you for volunteering to drive."
              )}
            </p>

            {showTimeModal && (
              <TimeModal
                isOpen={showTimeModal}
                pickupId={editingPickup}
                onClose={() => setShowTimeModal(false)}
                onSave={(onTime) => onSetTime(onTime)}
              />
            )}
            {showStatusModal && (
              <StatusModal
                isOpen={showStatusModal}
                pickupId={editingPickup}
                onClose={() => setShowStatusModal(false)}
                onSave={(onStatus) => onSetStatus(onStatus)}
              />
            )}

            {sortedPassengers.length > 0 && (
              <div className="bg-gray-100 p-3 rounded-lg mb-4">
                <h2 className="text-lg font-semibold">Pickup List:</h2>
                <ol className="list-decimal list-inside space-y-1 pl-4">
                  {sortedPassengers.map((passenger) => (
                    <PassengerDetail
                      passenger={passenger}
                      key={passenger.id}
                      openTimeModal={openTimeModal}
                      openStatusModal={openStatusModal}
                    />
                  ))}
                </ol>
              </div>
            )}
            {messageFromAdmin && (
              <div className="bg-yellow-100 p-3 rounded-lg mb-4">
                <h2 className="text-lg font-semibold">Message from Admin:</h2>
                <p>{messageFromAdmin}</p>
              </div>
            )}

            {!driverDetails.confirmedPickupAssignment &&
              sortedPassengers.length > 0 && (
                <div className="flex justify-center mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={handleSendConfirmation}
                    title="Click here to let the admin know that you have received the pickup list"
                  >
                    Acknowledge Assignments
                  </button>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};

export default DriverAssignmentList;
