import React, { useState, useEffect } from "react";

const AddOrEditUserModal = ({ isOpen, onClose, initialData, onSave }) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    isAdmin: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewUser, setIsNewUser] = useState(!initialData); // Determine if it's a new user based on initialData

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Update formData whenever initialData changes
  useEffect(() => {
    if (initialData) {
      setFormData({
        id: initialData.id || "",
        name: initialData.name || "",
        email: initialData.email || "",
        isAdmin: initialData.isAdmin || false,
      });
    } else {
      // Reset form data if adding a new user
      setFormData({
        id: "",
        name: "",
        email: "",
        isAdmin: false,
      });
    }
    setIsNewUser(!initialData);
  }, [initialData]);

  const validateForm = () => {
    // Basic validation logic here
    // Return false if validation fails, true otherwise
    if (!formData.name.trim()) {
      setError("Name is required.");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop the form submission if validation fails
    setLoading(true);
    // Simulate API call
    setTimeout(() => {
      onSave(formData);
      setLoading(false);
      setFormData({
        id: "",
        name: "",
        email: "",
        isAdmin: false,
      });
      onClose();
    }, 1000);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-blue-100 w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">
            {isNewUser ? "Add New" : "Edit"} User
          </p>

          <div className="modal-close cursor-pointer z-50" onClick={onClose}>
            {/* Close button SVG or other element here */}
          </div>
        </div>

        {isNewUser && (
          <div className="mb-4 text-sm text-gray-600">
            Note: The user will receive an email to complete their registration.
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={formData.name}
              onChange={handleChange}
              disabled={initialData ? true : false} // Disable input if editing (initialData exists)
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={formData.email}
              onChange={handleChange}
              disabled={!isNewUser} // Disable only if editing (isNewUser is false)
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="isAdmin"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Role
            </label>
            <select
              id="isAdmin"
              name="isAdmin"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={formData.isAdmin}
              onChange={handleChange}
              required
            >
              <option value={false}>User</option>
              <option value={true}>Admin</option>
            </select>
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save
            </button>
            <button
              className="bg-transparent hover:bg-grey text-grey-darkest font-semibold py-2 px-4 border border-grey rounded"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrEditUserModal;
