import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import EventCard from "./EventCard"; // Import the EventCard component
import useCRUD from "../../hooks/useCRUD"; // Adjust the import path as necessary
import { formatDateWithDay } from "../../utils/dateUtils"; // Make sure you have this utility function
import ConfirmModal from "../Elements/ConfirmModal";
const EventsPage = () => {
  const [activeTab, setActiveTab] = useState("future");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const history = useHistory();

  const {
    data: events = [], // Provide an empty array as a default value
    isLoading,
    error,
    fetchItems: getEvents,
    deleteItem,
  } = useCRUD("/api/events");

  const handleDeleteEvent = async (id) => {
    setShowConfirmModal(false);
    try {
      await deleteItem(id);
      getEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleManageClick = (id) => {
    // Navigate to the manage event page
    history.push(`/event/manage/${id}`);
  };

  const promptDeleteConfirmation = (id) => {
    setEventToDelete(id);
    setShowConfirmModal(true);
  };

  useEffect(() => {
    console.log("Fetching events...");
    getEvents().catch((err) => {
      console.log("Error fetching events:", err);
    });
  }, [getEvents]);

  console.log("Events:", events); // Add this line to check the fetched data

  // Filter events into future and past based on their dateOfOccurrence
  const futureEvents = events
    ?.filter((event) => new Date(event.dateOfOccurrence) >= new Date())
    .sort(
      (a, b) => new Date(a.dateOfOccurrence) - new Date(b.dateOfOccurrence)
    );
  console.log("Future Events:", futureEvents);

  // Sort past events in descending order
  const pastEvents = events
    ?.filter((event) => new Date(event.dateOfOccurrence) < new Date())
    .sort(
      (a, b) => new Date(a.dateOfOccurrence) - new Date(b.dateOfOccurrence)
    );
  console.log("Past Events:", pastEvents);

  return (
    <div className="container mx-auto p-4">
      {showConfirmModal && (
        <ConfirmModal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => handleDeleteEvent(eventToDelete)}
          message="Are you sure you want to delete this event?"
        />
      )}
      <div className="flex justify-end items-center mb-4">
        <Link
          to="/event/create"
          className="btn btn-primary"
          style={{ backgroundColor: "#1e3c54" }}
        >
          Create Event
        </Link>
      </div>

      <div className="tabs mb-4 flex border-b">
        <button
          onClick={() => setActiveTab("future")}
          className={`tab mr-1 pb-2 pt-1 px-4 text-sm font-medium ${
            activeTab === "future"
              ? "border-b-2 border-customBlue text-customBlue"
              : "text-gray-500 hover:text-gray-700"
          }`}
          style={{ transition: "all 0.2s ease-in-out" }}
        >
          Upcoming Events
        </button>
        <button
          onClick={() => setActiveTab("past")}
          className={`tab pb-2 pt-1 px-4 text-sm font-medium ${
            activeTab === "past"
              ? "border-b-2 border-customBlue text-customBlue"
              : "text-gray-500 hover:text-gray-700"
          }`}
          style={{ transition: "all 0.2s ease-in-out" }}
        >
          Past Events
        </button>
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error Fetching Events</p>
      ) : activeTab === "future" ? (
        futureEvents?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {futureEvents.map((event) => (
              <EventCard
                key={event.id}
                {...event}
                onManageClick={handleManageClick}
                onDeleteClick={() => promptDeleteConfirmation(event.id)}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No upcoming events to display.
          </div>
        )
      ) : activeTab === "past" ? (
        pastEvents?.length > 0 ? (
          <ul className="space-y-4">
            {pastEvents.map((event) => (
              <li
                key={event.id}
                className="bg-white shadow overflow-hidden rounded-md"
              >
                <div className="px-4 py-5 sm:p-6">
                  {/* Event Title */}
                  <h3 className="font-bold text-xl mb-2">{event.title}</h3>

                  {/* Event Date */}
                  <p className="text-gray-500 mb-4">
                    {formatDateWithDay(event.dateOfOccurrence)}
                  </p>

                  {/* Event Description */}
                  <p className="text-gray-600 mb-4">{event.description}</p>

                  {/* Action Buttons */}
                  <div className="flex justify-between">
                    <Link
                      to={`/event/manage/${event.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      View Details
                    </Link>
                    <button
                      className="text-red-500 hover:text-red-700"
                      onClick={() => promptDeleteConfirmation(event.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-gray-500">
            No past events to display.
          </div>
        )
      ) : null}
    </div>
  );
};

export default EventsPage;
