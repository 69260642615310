import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import AddOrEditParticipantModal from "../EventDashboard/AddorEditParticipantModal";
import Spinner from "../Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUsers } from "@fortawesome/free-solid-svg-icons";

const Participants = ({ participants, refreshData, event, groupId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const {
    addItem,
    updateItem,
    deleteItem,
    error: crudError,
  } = useCRUD("/api/participants");

  const [sortedParticipants, setSortedParticipants] = useState([]);

  const [error, setError] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "descending",
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleAddNewMemberClick = () => {
    console.log("Opening modal...");

    setIsModalOpen(true); // This should open the modal
    console.log("Modal should now be open, isModalOpen:", isModalOpen);

    setModalData(null); // Initialize data for new member
  };

  const handleEditClick = (participant) => {
    setIsModalOpen(true);
    setModalData({ ...participant, isDriver: false }); // Set modal for editing a member
  };

  const handleSave = async (data) => {
    try {
      setIsLoading(true);
      setError(""); // Reset error message on new save attempt

      let response; // This will store the response from your save functions

      if (modalData) {
        // Editing an existing participant
        response = await updateItem(modalData.id, data);
      } else {
        // Adding a new participant
        data.participantGroupId = groupId;
        response = await addItem(data);
      }
      console.log("response", response);

      // Check the response to ensure it was successful
      // This assumes your addItem/updateItem functions return some success indicator
      // Adjust the following line according to your actual API response structure
      if (response && response.data) {
        console.log("Participant saved successfully:", data);
        // Optionally, refresh your participants list here
      } else {
        // Handle unsuccessful save
        // Adjust the following line according to your actual API response structure
        throw new Error(
          response.message || "An error occurred while saving the participant."
        );
      }
    } catch (error) {
      console.error("Error saving participant:", error);
      console.log("crudError", crudError);
      setError(error.message || "Error saving participant. Please try again.");
    } finally {
      setIsModalOpen(false); // Close the modal after attempting save
      refreshData(); // Refresh data regardless of success/failure
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (participants) {
      let sortedParticipants = [...participants];
      if (sortConfig.key !== null) {
        sortedParticipants.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }

      setSortedParticipants(sortedParticipants);
    }
  }, [participants, sortConfig]);

  const handleDeleteClick = async (participant) => {
    try {
      setIsLoading(true);
      await deleteItem(participant.id);
      console.log("Participant deleted successfully:", participant);
      // Optionally, refresh your participants list here
    } catch (error) {
      console.error("Error deleting participant:", error);
    } finally {
      refreshData();
      setIsLoading(false);
    }
  };

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      {isLoading && <Spinner />}

      {error && <p className="text-red-500">{error}</p>}

      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {participants?.length} Event Members
          </h3>
          <button
            className="btn bg-[#004a7e] text-white mt-4 hover:bg-[#004a7e] hover:text-white btn-sm"
            onClick={handleAddNewMemberClick}
          >
            + Add New Member
          </button>{" "}
          {isModalOpen && (
            <AddOrEditParticipantModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              initialData={modalData}
              isDriver={false}
              onSave={handleSave}
              event={event}
            />
          )}
        </div>

        {sortedParticipants?.length > 0 ? (
          <div className="">
            <table className="table w-full hidden md:table">
              <thead>
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Member{" "}
                    {sortConfig.key === "name" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>

                  <th onClick={() => requestSort("rideLocation")}>
                    Pickup Location{" "}
                    {sortConfig.key === "rideLocation" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortedParticipants.map((participant) => (
                  <tr key={participant.id}>
                    <td>
                      <div className="flex items-center gap-3">
                        <div>
                          <div className="font-bold">
                            {participant.name}
                            {participant.pauseNotifications && (
                              <span className="ml-2 bg-red-500 text-white py-1 px-2 text-xs rounded">
                                Paused
                              </span>
                            )}
                            {participant.noOfPpl > 1 && (
                              <span className="ml-2 bg-[#004a7e] text-white py-1 px-2  text-xs">
                                {participant.noOfPpl}{" "}
                                <FontAwesomeIcon icon={faUsers} />
                              </span>
                            )}
                          </div>
                          <div className="text-sm opacity-50">
                            {participant.phone}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{participant.rideLocation}</td>

                    <td>
                      <div className="dropdown dropdown-top">
                        <button
                          tabIndex="0"
                          className="btn btn-ghost btn-xs m-1"
                        >
                          ...
                        </button>
                        <ul
                          tabIndex="0"
                          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                        >
                          <li>
                            <button
                              onClick={() => handleEditClick(participant)}
                            >
                              Edit
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => handleDeleteClick(participant)}
                            >
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="md:hidden">
              {sortedParticipants.map((participant) => (
                <div
                  key={participant.id}
                  className="p-4 border-b flex items-center relative"
                >
                  <div className="flex-grow">
                    <a
                      href={`sms:${participant.phone}`}
                      className="font-bold hover:text-blue-700"
                    >
                      {participant.name}{" "}
                      {participant.pauseNotifications && (
                        <span className="ml-2 bg-red-500 text-white py-1 px-2 text-xs rounded">
                          Paused
                        </span>
                      )}
                      {participant.noOfPpl > 1 && (
                        <span className="ml-2 bg-[#004a7e] text-white py-1 px-2  text-xs">
                          {participant.noOfPpl}{" "}
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                      )}
                    </a>
                    <div className="text-sm text-gray-500">
                      {participant.rideLocation}
                    </div>
                  </div>
                  <div className="dropdown dropdown-end">
                    <button tabIndex="0" className="btn btn-ghost btn-xs m-1">
                      ...
                    </button>
                    <ul
                      tabIndex="0"
                      className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-30"
                    >
                      <li>
                        <a
                          href="#"
                          onClick={() => handleEditClick(participant)}
                          className="justify-between"
                        >
                          Edit
                        </a>
                      </li>
                      <li>
                        <button onClick={() => handleDeleteClick(participant)}>
                          Delete
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>No participants to display.</p>
        )}
      </div>
    </div>
  );
};

export default Participants;
