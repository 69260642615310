import React, { useState } from "react";
import InputMask from "react-input-mask";
import debounce from "lodash/debounce"; // Assuming you have lodash installed
import useCRUD from "../../hooks/useCRUD";

const AddOrEditParticipantModal = ({
  isOpen,
  onClose,
  isDriver,
  initialData,
  onSave,
  event,
}) => {
  const [formData, setFormData] = React.useState({
    name: initialData?.name || "",
    firstName: initialData?.firstName || "",
    lastName: initialData?.lastName || "",
    phone: initialData?.phone || "",
    noOfPpl: initialData?.noOfPpl || 1,
    rideLocation: isDriver ? undefined : initialData?.rideLocation || "",
    canTake: isDriver ? initialData?.canTake || 0 : undefined,
    isDriver: isDriver,
    participantGroupId:
      initialData?.participantGroupId || event?.participantGroup,
    pauseNotifications: initialData?.pauseNotifications || false, // Add new state for pause field
  });
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState(null);

  const { addItem } = useCRUD("/api/participants/suggestions");
  // Debounce the fetchSuggestions function to prevent excessive API calls
  const debouncedFetchSuggestions = debounce((searchTerm) => {
    if (searchTerm.trim().length >= 3) {
      // Only fetch suggestions if input length is at least 3
      fetchSuggestions(searchTerm);
    } else {
      setSuggestions([]); // Clear suggestions if input length is less than 3
    }
  }, 300); // Adjust debounce time as needed

  const handleChange = (e) => {
    const { name, value } = e.target;
    const previousValueLength = formData[name]?.length || 0; // Get the previous length of the value for this field

    setFormData((prev) => ({ ...prev, [name]: value }));

    // Check if initialData is present (indicating editing) and if the name is "firstName"
    if (
      !initialData &&
      name === "firstName" &&
      value.length >= 3 &&
      value.length > previousValueLength
    ) {
      debouncedFetchSuggestions(value);
    }
  };

  const fetchSuggestions = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSuggestions([]);
      return;
    }
    setLoading(true);
    try {
      // Assuming 'account_id' is available in your component's props or state. Adjust as necessary.
      const response = await addItem({
        searchTerm,
      });

      const data = response.data;
      // Assuming the API returns suggestions with 'name', 'phone', 'firstName', 'lastName', 'canTake', 'rideLocation'
      setSuggestions(
        data.map((s) => ({
          name: s.name,
          phone: s.phone,
          firstName: s.firstName,
          lastName: s.lastName,
          canTake: s.canTake,
          rideLocation: s.rideLocation,
          noOfPpl: s.noOfPpl,
        }))
      ); // Map the response data to the desired structure if needed
      setShowSuggestions(true);
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const phoneNumberMask =
    formData.phone && formData.phone.length > 10
      ? "1 (999) 999-9999"
      : "(999) 999-9999";

  const validateFormData = () => {
    if (!formData.firstName || !formData.phone) {
      setError("First Name and phone number are required");
      return false;
    }

    if (isDriver && !formData.canTake) {
      setError("Can take is required");
      return false;
    }

    if (!isDriver && !formData.rideLocation) {
      setError("Ride location is required");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateFormData()) return;
    onSave(formData);
    onClose();
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-blue-100 w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">
            {initialData ? "Edit" : "Add New"} {isDriver ? "Driver" : "Member"}
          </p>
          <div className="modal-close cursor-pointer z-50" onClick={onClose}>
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          {error && <p className="text-red-500">{error}</p>}
          <div className="flex justify-between space-x-4 mb-4">
            <div className="flex-1">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                First Name
              </label>
              <input
                type="text"
                name="firstName" // Corrected to match the state property
                value={formData.firstName}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                required
                autoComplete="off" // Disable browser's autocomplete
              />

              {showSuggestions && (
                <ul className="absolute z-10 w-full list-none bg-white border mt-1 shadow-lg max-h-60 overflow-auto">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setFormData({
                          ...formData,
                          firstName: suggestion?.firstName
                            ? suggestion.firstName
                            : "",
                          lastName: suggestion?.lastName
                            ? suggestion.lastName
                            : "",
                          phone: suggestion?.phone ? suggestion.phone : "",
                          canTake: suggestion?.canTake
                            ? suggestion.canTake
                            : "",
                          rideLocation: suggestion?.rideLocation
                            ? suggestion.rideLocation
                            : "",
                        });
                        setShowSuggestions(false); // Hide suggestions after selection
                      }}
                    >
                      {suggestion.firstName} {suggestion.lastName} -{" "}
                      {suggestion.phone}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Last Name
              </label>
              <input
                type="text"
                name="lastName" // Corrected to match the state property
                value={formData.lastName}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              Phone
            </label>
            <InputMask
              mask={phoneNumberMask}
              value={formData.phone}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              name="phone"
              required
            />
          </div>
          {!isDriver && (
            //Add new field for no. of people
            <>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  No. of People
                </label>
                <input
                  type="number"
                  name="noOfPpl"
                  value={formData.noOfPpl}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                />
              </div>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  Ride Location
                </label>
                <input
                  type="text"
                  name="rideLocation"
                  value={formData.rideLocation}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                />
              </div>
            </>
          )}
          {isDriver && (
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Can Take (No. of people)
              </label>
              <input
                type="number"
                name="canTake"
                value={formData.canTake}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              />
            </div>
          )}

          {initialData && (
            <div className="form-control w-full max-w-xs">
              <label className="label cursor-pointer">
                <span className="label-text">
                  {formData?.pauseNotifications ? "Resume" : "Pause"}{" "}
                  {isDriver ? "Driver" : "Member"}
                </span>
                <input
                  type="checkbox"
                  checked={formData.pauseNotifications}
                  onChange={handleToggle}
                  name="pauseNotifications"
                  className="toggle toggle-error"
                />
              </label>
            </div>
          )}

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save
            </button>
            <button
              className="bg-transparent hover:bg-grey text-grey-darkest font-semibold py-2 px-4 border border-grey rounded"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrEditParticipantModal;
