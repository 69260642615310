import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Spinner/Spinner";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [loading, setLoading] = useState(true); // Start with loading true to check token
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const { tokenEmail } = useParams(); // Capture the token from the URL
  const [isTokenValid, setIsTokenValid] = useState(false); // State to track token validity

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!tokenEmail) {
          setErrorMessage("Token is invalid or has expired.");
          setIsTokenValid(false);
          return;
        }

        const decoded = atob(tokenEmail); // Decoding the base64 string
        const [token, email] = decoded.split("|"); // Splitting to get token and email
        setFormData({ ...formData, token, email });

        const apiBaseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(
          `${apiBaseUrl}/api/check-reset-token`,
          { token, email }
        );

        if (response.data.success) {
          setIsTokenValid(true);
        } else {
          setErrorMessage("Token is invalid or has expired.");
          setIsTokenValid(false);
        }
      } catch (error) {
        setErrorMessage("Failed to verify token.");
        console.error("Error verifying token:", error);
      } finally {
        setLoading(false);
      }
    };

    verifyToken();
  }, [tokenEmail]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isTokenValid) {
      setErrorMessage("Token is invalid or has expired.");
      return;
    }
    setErrorMessage("");
    setLoading(true);

    if (
      !formData.password ||
      formData.password !== formData.password_confirmation
    ) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      const decoded = atob(tokenEmail); // Decoding the base64 string
      const [token, email] = decoded.split("|"); // Splitting to get token and email

      const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure this is set in your environment
      const response = await axios.post(`${apiBaseUrl}/api/reset-password`, {
        token,
        email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
      });

      if (response.status === 200) {
        setSuccessMessage(
          "Your password has been reset successfully. Please log in."
        );
        alert("Your password has been reset successfully. Please log in.");
        history.push("/login");
      } else {
        setErrorMessage("Failed to reset password");
      }
    } catch (error) {
      if (error.response && error.response.data.errors) {
        // Constructing a single string with all error messages
        const errors = error.response.data.errors;
        const errorMessages = Object.keys(errors)
          .map((field) => `${errors[field].join(" ")}`)
          .join(" ");

        setErrorMessage(errorMessages);
      } else {
        setErrorMessage(
          error.response?.data?.message ||
            "An error occurred during password reset."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Spinner />;

  if (!isTokenValid) {
    return (
      <div
        className="flex min-h-screen bg-cover"
        style={{ backgroundImage: "url('/background.png')" }}
      >
        <div className="m-auto w-full max-w-md px-6 py-12 bg-white shadow-md overflow-hidden sm:rounded-lg">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-md font-extrabold text-gray-900">
                Token is invalid or has expired. To reset your password, please
                go to{" "}
                <a className="text-primary" href="/login">
                  Login Page
                </a>{" "}
                and click on "Forgot Password".
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex min-h-screen bg-cover"
      style={{ backgroundImage: "url('/background.png')" }}
    >
      <div className="m-auto w-full max-w-md px-6 py-12 bg-white shadow-md overflow-hidden sm:rounded-lg">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Reset Your Password
            </h2>
          </div>

          <div className="mt-8">
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            {successMessage && (
              <div className="text-green-500">{successMessage}</div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="password_confirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm New Password
                </label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
