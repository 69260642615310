import { useState, useContext, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import useCRUD from "./useCRUD";

const useFeatureAvailability = (featureKey) => {
  const { userPlan } = useAuth();
  const [isFeatureAvailable, setIsFeatureAvailable] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: featuresData,
    fetchData: fetchFeatures,
    isLoading: featuresLoading,
    error: featuresError,
  } = useCRUD("/api/subscription-features");

  useEffect(() => {
    fetchFeatures();
  }, [fetchFeatures]);

  useEffect(() => {
    setIsLoading(featuresLoading);
    setError(featuresError);
    if (featuresData && featuresData.features) {
      const available = Object.hasOwnProperty.call(
        featuresData.features,
        featureKey
      );
      setIsFeatureAvailable(available);
      if (!available) {
        setTooltipMessage("Upgrade your plan to unlock this feature");
      } else {
        setTooltipMessage(""); // Reset tooltip message if the feature is available
      }
    }
  }, [featuresData, featureKey, featuresLoading, featuresError]);

  return { isFeatureAvailable, tooltipMessage, isLoading, error };
};

export default useFeatureAvailability;
