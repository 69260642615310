// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { useAuth } from "../context/AuthContext"; // Adjust the path as needed

// import Spinner from "./Spinner/Spinner";

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { isLoggedIn, userPlan, isLoading: loading } = useAuth(); // Use the context to get user's login status and plan
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         loading ? (
//           // Show a loader or return null while loading
//           <Spinner />
//         ) : isLoggedIn && userPlan ? (
//           <Component {...props} />
//         ) : !isLoggedIn ? (
//           <Redirect to="/login" />
//         ) : (
//           <Redirect to="/choose-plan" />
//         )
//       }
//     />
//   );
// };

// const AdminRoute = ({ component: Component, ...rest }) => {
//   const { isLoggedIn, isAdminUser, userPlan, isLoading: loading } = useAuth(); // Use the context

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         // Check if the user is logged in, an admin, and has a plan

//         loading ? (
//           // Show a loader or return null while loading
//           <Spinner />
//         ) : isLoggedIn && isAdminUser && userPlan ? (
//           <Component {...props} />
//         ) : !isLoggedIn ? (
//           // If not logged in, redirect to login
//           <Redirect to="/login" />
//         ) : (
//           // If logged in but doesn't meet the other conditions, redirect appropriately
//           <Redirect to="/choose-plan" />
//         )
//       }
//     />
//   );
// };

// export { PrivateRoute, AdminRoute };

import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isAuthenticated, isAdmin } from "../utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && isAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export { PrivateRoute, AdminRoute };
