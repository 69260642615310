import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useCRUD from "../../hooks/useCRUD";
import TemplateField from "../Elements/TemplateField";
import WithFeatureRestriction from "../WithFeatureRestriction";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

const AddEvent = () => {
  const { addItem } = useCRUD("/api/events");

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDateTimeChange = (date, fieldName) => {
    // Convert the date to UTC and format it for submission
    const utcDateString = moment(date).utc().format();

    setFormData({
      ...form,
      [fieldName]: utcDateString,
    });
  };

  const templateVariables = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{link}}",
  ];

  const templateVariablesForDrivers = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{driverName}}",
    "{{driverPhone}}",
  ];

  const usTimeZones = [
    { label: "Eastern Time (ET)", value: "America/New_York" },
    { label: "Central Time (CT)", value: "America/Chicago" },
    { label: "Mountain Time (MT)", value: "America/Denver" },
    { label: "Mountain Time (MT) no DST", value: "America/Phoenix" },
    { label: "Pacific Time (PT)", value: "America/Los_Angeles" },
    { label: "Alaska Time (AKT)", value: "America/Anchorage" },
    { label: "Hawaii Time (HT)", value: "Pacific/Honolulu" },
  ];

  const [form, setFormData] = useState({
    title: "",
    description: "",
    location: "",
    dateOfOccurrence: "",
    isRecurring: false,
    recurrenceDetails_endDate: "",
    recurrenceDetails_pattern: "",
    endDate: "",
    pingParticipants: false,
    pingDrivers: false,
    pingSchedule: "",
    participantTemplate: "",
    driverTemplate: "",
    riderAssignment: "",
    eventMessageForDrivers: "",
    eventTimeZone: "America/New_York", // Use IANA timezone format as default
  });

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...form, [e.target.name]: value });
  };

  const validateForm = () => {
    // Basic validation logic here
    // Return false if validation fails, true otherwise
    if (!form.title.trim()) {
      setError("Event Name is required.");
      return false;
    }
    if (!form.dateOfOccurrence) {
      setError("Event Date is required.");
      return false;
    }
    if (form.isRecurring && !form.recurrenceDetails_pattern) {
      setError("Recurrence pattern is required for recurring events.");
      return false;
    }

    if ((form.pingParticipants || form.pingDrivers) && !form.pingSchedule) {
      setError("Reminder Dateis required when sending reminders.");
      return false;
    }
    if (new Date(form.pingSchedule) >= new Date(form.dateOfOccurrence)) {
      setError("Reminder Date must be before the Date of Event");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setIsLoading(true);
    setError(""); // Reset error message

    try {
      await addItem(form);
      history.push("/"); // Redirect to home page on successful submission
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Add New Event</h2>
      {error && <div className="text-red-500">{error}</div>}

      <form
        onSubmit={handleSubmit}
        className="space-y-6 bg-white shadow-sm rounded-md p-6"
      >
        {/* Basic Event Details Section */}
        <div className="space-y-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Basic Event Details
          </h3>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Event Name
              </label>
              <input
                id="title"
                name="title"
                type="text"
                value={form.title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location
              </label>
              <input
                id="location"
                name="location"
                type="text"
                value={form.location}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={form.description}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="dateOfOccurrence"
                className="block text-sm font-medium text-gray-700"
              >
                Event Date
              </label>

              <DatePicker
                selected={
                  form.dateOfOccurrence ? new Date(form.dateOfOccurrence) : ""
                }
                onChange={(date) =>
                  handleDateTimeChange(date, "dateOfOccurrence")
                }
                showTimeSelect
                timeFormat="h:mm aa"
                timeIntervals={15}
                minDate={new Date()}
                name="dateOfOccurrence"
                timeCaption="time"
                dateFormat="yyyy-MM-dd h:mm aa"
                className="mt-1 w-full block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="eventTimeZone"
                className="block text-sm font-medium text-gray-700"
              >
                Event Timezone
              </label>
              <select
                id="eventTimeZone"
                name="eventTimeZone"
                value={form.eventTimeZone}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                {usTimeZones.map((zone) => (
                  <option key={zone.value} value={zone.value}>
                    {zone.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* Recurring Event Section */}
        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              Recurring Event Settings
            </legend>
            <div className="mt-4 space-y-4">
              <WithFeatureRestriction featureKey="recurring_events">
                {(isDisabled) => (
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="isRecurring"
                        name="isRecurring"
                        type="checkbox"
                        checked={form.isRecurring}
                        onChange={handleChange}
                        disabled={isDisabled}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isRecurring"
                        className="font-medium text-gray-700"
                      >
                        Is this Event Recurring?
                      </label>
                    </div>
                  </div>
                )}
              </WithFeatureRestriction>
              {form.isRecurring && (
                <>
                  <div>
                    <label
                      htmlFor="recurrenceDetails_pattern"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Recurrence Pattern
                    </label>
                    <select
                      id="recurrenceDetails_pattern"
                      name="recurrenceDetails_pattern"
                      value={form.recurrenceDetails_pattern}
                      onChange={handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required={form.isRecurring}
                    >
                      <option value="">Select a pattern</option>
                      <option value="Weekly">Weekly</option>
                      {/* Add other patterns as necessary */}
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="recurrenceDetails_endDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Recurrence End Date
                    </label>
                    <input
                      id="recurrenceDetails_endDate"
                      type="date"
                      name="recurrenceDetails_endDate"
                      value={form.recurrenceDetails_endDate}
                      onChange={handleChange}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required={form.isRecurring}
                    />
                  </div>
                </>
              )}
            </div>
          </fieldset>
        </div>
        {/* Reminder Settings Section */}
        <div className="border-t border-gray-200 pt-4">
          <fieldset>
            <legend className="text-lg font-medium text-gray-900">
              Reminder Settings
            </legend>
            <div className="mt-4 space-y-4">
              {/* Reminder configurations similar to the above sections */}

              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id="pingParticipants"
                    name="pingParticipants"
                    checked={form.pingParticipants}
                    onChange={handleChange}
                  />
                  <span className="ml-2">
                    Send Reminders to Participants for availability before
                    event?
                  </span>
                </label>
              </div>
              <div>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id="pingDrivers"
                    name="pingDrivers"
                    checked={form.pingDrivers}
                    onChange={handleChange}
                  />
                  <span className="ml-2">
                    Send Reminders to Drivers for availability before event?
                  </span>
                </label>
              </div>
              {(form.pingParticipants || form.pingDrivers) && (
                <div>
                  <label
                    htmlFor="pingSchedule"
                    className="block text-sm font-medium text-gray-700"
                  >
                    When should reminders go out?
                  </label>

                  <DatePicker
                    selected={
                      form.pingSchedule ? new Date(form.pingSchedule) : ""
                    }
                    onChange={(date) =>
                      handleDateTimeChange(date, "pingSchedule")
                    }
                    showTimeSelect
                    timeFormat="h:mm aa"
                    name="pingSchedule"
                    timeIntervals={60}
                    minDate={new Date()}
                    timeCaption="time"
                    dateFormat="yyyy-MM-dd h:mm aa"
                    className="mt-1 w-full block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              )}
            </div>
          </fieldset>
        </div>

        {error && <div className="text-red-500 font-bold">{error}</div>}
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {isLoading ? "Saving..." : "Create Event"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEvent;
