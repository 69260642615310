import React from "react";

const DownloadCsvTemplate = () => {
  const handleDownloadTemplate = () => {
    const templateData = [
      [
        "firstName",
        "lastName",
        "phone",
        "rideLocation",
        "isDriver",
        "canTake",
        "noOfPpl",
      ], // Column headers
      ["John", "Doe", "123-456-7890", "123 Main St", "true", 5, 5], // Sample data row
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      templateData.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "participants_template.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "participants_template.csv".
  };

  return (
    <button onClick={handleDownloadTemplate} className="btn btn-primary">
      Download CSV Template
    </button>
  );
};

export default DownloadCsvTemplate;
