import React, { useState, useRef } from "react";

const TemplateField = ({
  id,
  label,
  tooltip,
  placeholder,
  templateVariables,
  value,
  onChange,
}) => {
  const [selectedVariable, setSelectedVariable] = useState(
    templateVariables[0] || ""
  );
  const textareaRef = useRef(null);

  const handleInsertVariable = () => {
    const currentValue = value;
    const textarea = textareaRef.current;
    if (!textarea) return;

    const before = currentValue.substring(0, textarea.selectionStart);
    const after = currentValue.substring(
      textarea.selectionEnd,
      currentValue.length
    );
    const newValue = `${before}${selectedVariable}${after}`;

    onChange({ target: { name: id, value: newValue } }); // Ensure this matches your form's state structure

    // Optional: focus the textarea and position the cursor right after the inserted variable
    setTimeout(() => {
      textarea.focus();
      const pos = before.length + selectedVariable.length;
      textarea.setSelectionRange(pos, pos);
    }, 0);
  };

  return (
    <div>
      <label
        htmlFor={id}
        className="text-sm font-bold text-gray-700 flex items-center"
      >
        {label}
        {tooltip && (
          <div className="tooltip ml-2 flex items-center" data-tip={tooltip}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4" // Adjusted size here
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ marginBottom: "2px" }} // Adjust for better alignment if necessary
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        )}
      </label>

      <div className="flex space-x-2 mt-1">
        <select
          className="border p-2 rounded-md"
          value={selectedVariable}
          onChange={(e) => setSelectedVariable(e.target.value)}
        >
          {templateVariables.map((variable, index) => (
            <option key={index} value={variable}>
              {variable.substring(2, variable.length - 2)}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={handleInsertVariable}
          className="bg-primary btn-sm  text-white font-bold py-2 px-4 rounded"
        >
          Insert
        </button>
      </div>
      <div className="flex flex-col space-y-2 mt-1">
        <textarea
          ref={textareaRef}
          className="border p-2 rounded w-full"
          id={id}
          name={id}
          placeholder=""
          value={value}
          onChange={onChange}
        />
        <p className="text-sm text-gray-500">Example: {placeholder}</p>{" "}
        {/* Displaying placeholder as an example */}
      </div>
    </div>
  );
};

export default TemplateField;
