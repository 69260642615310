import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDateWithDay } from "../../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const EventDetails = ({ event, isEventExpired, refreshData }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const drawerRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsDrawerOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerRef]);

  if (!event) return "Loading...";

  return (
    <div className="event-details bg-white shadow overflow-hidden sm:rounded-lg relative">
      <div className="px-4 py-5 sm:px-6 border-b border-gray-200 text-center">
        {/* Event Title */}
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {event.title}
        </h3>
        <p className="text-sm leading-6 text-gray-500">
          {formatDateWithDay(event.dateOfOccurrence)} | {event.location}
        </p>

        <button
          onClick={refreshData}
          className="absolute top-5 right-4 p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none"
          title="Refresh"
        >
          <FontAwesomeIcon icon={faSync} />
        </button>
      </div>

      {/* Drawer Toggle */}
      <button
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        className="absolute top-5 left-4 p-2 rounded-md text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          style={{ transform: "scaleX(-1)" }} // This line flips the SVG
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>

      {/* Side Drawer */}
      <div
        ref={drawerRef}
        className={`transform top-0 left-0 w-64 bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50 shadow-xl ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Close Button */}
        <div className="p-4 text-right">
          <button
            onClick={() => setIsDrawerOpen(false)}
            className="text-gray-600 hover:text-gray-800"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>

        {/* Drawer Content */}
        <nav className="flex flex-col p-4 space-y-2">
          <Link
            to={`/event/edit/${event.id}`}
            className="p-3 hover:bg-blue-50 rounded-lg text-primary font-medium"
          >
            {isEventExpired ? "View Event Details" : "Edit Event"}
          </Link>
          <Link
            to={`/event/sms-template/${event.id}`}
            className="p-3 hover:bg-blue-50 rounded-lg text-primary font-medium"
          >
            SMS Templates
          </Link>
          <Link
            to={`/group/${event.participantGroup}`}
            className="p-3 hover:bg-blue-50 rounded-lg text-primary font-medium"
          >
            Manage Group
          </Link>
          {/* Add more links as needed */}
        </nav>
      </div>
    </div>
  );
};

export default EventDetails;
