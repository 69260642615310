import React, { useState, useEffect } from "react";
import useCRUD from "../hooks/useCRUD";
import Spinner from "../components/Spinner/Spinner";

const Profile = () => {
  const {
    data: userDetails,
    fetchItems: fetchUser,
    addItem: updateUserDetails,
  } = useCRUD("/api/user");
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [toast, setToast] = useState(false);

  useEffect(() => {
    fetchUser();
    // Assuming userDetails contains the user's name once fetched
    if (userDetails) {
      setName(userDetails.name);
    }
  }, [fetchUser]);

  const hasChanges = () => {
    return (
      userDetails.name !== name ||
      newPassword.length > 0 ||
      confirmPassword.length > 0
    );
  };

  const showSuccessToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false); // Hide toast after 3 seconds
    }, 3000);
  };

  const handleUpdateProfile = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }
    // Clear error on successful validation
    setError("");

    await updateUserDetails({ name, newPassword });
    showSuccessToast(); // Show toast and redirect on success
    // Implement the API call to update the user profile here, including name and/or password as needed
  };

  if (!userDetails) return <Spinner />;

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl mb-4 font-semibold">Edit Profile</h2>
        {error && <p className="text-red-500 text-xs italic">{error}</p>}

        {toast && (
          <div className="fixed top-20 right-20 bg-green-500 text-white p-3 rounded">
            Changes saved successfully!
          </div>
        )}

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            name="name"
            placeholder="Your Name"
            value={name || userDetails.name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 bg-gray-100 cursor-not-allowed leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Your Email"
            disabled={true}
            value={userDetails.email}
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Account
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 bg-gray-100 cursor-not-allowed leading-tight focus:outline-none focus:shadow-outline"
            id="account"
            type="text"
            placeholder="Account"
            disabled={true}
            value={userDetails?.account?.name}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="new-password"
          >
            New Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="new-password"
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="confirm-password"
          >
            Confirm Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="confirm-password"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            className={`bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
              !hasChanges() ? "opacity-50 cursor-not-allowed" : ""
            }`}
            type="button"
            onClick={handleUpdateProfile}
            disabled={!hasChanges()}
          >
            Update Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
