import React, { useState, useEffect, Fragment, useRef } from "react";
import useCRUD from "../../hooks/useCRUD";
import { formatRelativeTime } from "../../utils/dateUtils";
import { Menu, Transition } from "@headlessui/react";

const AccountNotifications = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const [activeTab, setActiveTab] = useState("Unread"); // 'All' or 'Unread'
  const { data: actionHistory, fetchItems: fetchActionHistory } = useCRUD(
    "/api/action-history"
  );
  const { updateItem: markAsRead } = useCRUD("/api/action-history");

  const { addItem } = useCRUD("/api/action-history/mark-all-read");
  const dropdownRef = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  // Use state to store fetched notifications

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        showDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  // useEffect(() => {
  //   fetchActionHistory();
  // }, [fetchActionHistory]);

  useEffect(() => {
    // Function to fetch notifications
    const fetchNotifications = async () => {
      if (!notifications) {
        setIsLoading(true);
        try {
          await fetchActionHistory();
          setNotifications(actionHistory);
        } catch (error) {
          console.error("Failed to fetch notifications:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    // Fetch notifications on mount
    fetchNotifications();

    // Set up interval to fetch notifications every 5 minutes
    const interval = setInterval(fetchNotifications, 300000); // 300000 ms = 5 minutes

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [fetchActionHistory, notifications]);

  const toggleDropdown = async () => {
    setShowDropdown(!showDropdown);
    // Fetch notifications only if they haven't been fetched yet
    if (!showDropdown && notifications.length === 0) {
      // Change this line
      setIsLoading(true);
      try {
        await fetchActionHistory();
        setNotifications(actionHistory);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleNotificationClick = async (notificationId) => {
    await markAsRead(notificationId, { isRead: true });
    fetchActionHistory();
  };

  const interpretAction = (action) => {
    const {
      participantId,
      actionType,
      details: details1,
      timestamp,
      eventId,
    } = action;
    const relativeTime = formatRelativeTime(new Date(timestamp));
    let actionDescription = "";

    // Initialize parsedDetails as an empty object
    let details = {};

    // Check if details is a valid JSON string before parsing
    if (details1) {
      try {
        details = JSON.parse(details1);
      } catch (e) {
        console.error("Error parsing details:", e);
        // Handle parsing error (e.g., set parsedDetails to an empty object or log the error)
        details = {}; // Assuming details is an associative array, keep it as an object
      }
    }
    console.log(details);
    switch (actionType) {
      case "UpdateAvailability":
        if (participantId.isDriver) {
          actionDescription =
            details.availability === "Yes"
              ? `Driver ${participantId.name} indicated they can help with pickups for ${eventId.title}.`
              : `Driver ${participantId.name} indicated they cannot help with pickups for ${eventId.title}.`;
        } else {
          actionDescription =
            details.needRide === "Yes"
              ? `${participantId.name} indicated they need a ride for ${eventId.title}.`
              : `${participantId.name} indicated they don't need a ride for ${eventId.title}.`;
        }
        break;
      case "MessageForAdmin":
        actionDescription = `${participantId.name} sent a message to admin: ${details.messageForAdmin} for ${eventId.title}.`;
        break;

      case "UpdateInfo":
        if (details.canTake !== undefined) {
          actionDescription = `${participantId.name} updated that they can take ${details.canTake} people for ${eventId.title}.`;
        } else if (details.rideLocation !== undefined) {
          actionDescription = `${participantId.name} updated their ride location to ${details.rideLocation} for ${eventId.title}.`;
        }
        break;
      case "AssignmentConfirmation":
        actionDescription = `Driver ${participantId.name} confirmed their pickup list for ${eventId.title}.`;
        break;
      default:
        actionDescription = `Action performed by ${participantId.name}.`;
    }
    return {
      description: actionDescription, // The main action text
      time: relativeTime, // The relative timestamp
    };
  };

  const sortedNotifications = notifications?.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  const unreadNotifications =
    sortedNotifications?.filter((notification) => !notification.read) || [];

  const displayNotifications =
    activeTab === "Unread" ? unreadNotifications : sortedNotifications;
  const markAllAsRead = async () => {
    // Assuming you have an endpoint that handles marking all notifications as read
    // Adjust according to your actual API
    await addItem({ isRead: true });
    fetchActionHistory();
  };
  return (
    <div className="relative">
      <button onClick={toggleDropdown} className="relative">
        <i className="fas fa-bell text-xl"></i>
        {notifications?.some((notification) => !notification.read) && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            {notifications.filter((notification) => !notification.read).length}
          </span>
        )}
      </button>

      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 bg-white rounded-md shadow-lg z-50 overflow-auto
          min-w-200  sm:w-50 max-h-96" // Adjusts minimum width and width for small screens and up
          style={{ maxHeight: "400px" }}
        >
          <div className="flex justify-between items-center px-4 py-2 bg-gray-100">
            <div className="flex space-x-2">
              <button
                className={`font-bold ${
                  activeTab === "Unread" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("Unread")}
              >
                Unread
              </button>
              <button
                className={`font-bold ${
                  activeTab === "All" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => setActiveTab("All")}
              >
                All
              </button>
            </div>
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600">
                <i className="fas fa-ellipsis-h"></i>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-48 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={markAllAsRead}
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          ✓ Mark All as Read
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <ul className="py-1">
            {displayNotifications?.length > 0 ? (
              displayNotifications.map((notification) => {
                const { description, time } = interpretAction(notification);
                return (
                  <li
                    key={notification.id}
                    className={`px-4 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200 ${
                      !notification.read ? "bg-gray-50" : ""
                    }`} // Apply a different background color for unread messages
                  >
                    <div
                      onClick={() =>
                        !notification.read &&
                        handleNotificationClick(notification.id)
                      }
                      className="flex justify-between items-center"
                    >
                      <div
                        className={`flex-grow ${
                          !notification.read ? "font-bold" : ""
                        }`}
                      >
                        {" "}
                        {/* Make text bold for unread messages */}
                        <p>{description}</p>
                        <p className="text-right text-xs text-gray-500">
                          {time}
                        </p>
                      </div>
                      {!notification.read && (
                        <span className="flex-shrink-0 ml-2 h-3 w-3 bg-blue-500 rounded-full border-2 border-white">
                          {" "}
                          {/* Increase size and add border */}
                        </span>
                      )}
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="px-4 py-2 text-gray-700">
                No {activeTab.toLowerCase() == "all" ? "" : "unread"}{" "}
                notifications
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountNotifications;
