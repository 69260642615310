import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-xl font-bold my-4">Terms and Conditions</h1>
      <p>Your Terms and Conditions content goes here...</p>
      {/* Add more content as needed */}
    </div>
  );
};

export default TermsAndConditions;
