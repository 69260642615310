import React, { useState, useEffect } from "react";

const AssignDriverModal = ({
  isOpen,
  onClose,
  participants,
  allParticipants,
  availableDrivers,
  onAssign,
}) => {
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const [warning, setWarning] = useState("");

  useEffect(() => {
    if (isOpen) {
      setSelectedDriverId("");
      setWarning("");
    }
  }, [isOpen]);

  // Calculate the available capacity for each driver
  const driversWithAvailableCapacity = availableDrivers
    .map((driver) => {
      const assignedLoad = allParticipants.reduce((total, participant) => {
        return participant.assignedDriverId &&
          participant.assignedDriverId.id === driver.participantId
          ? total + participant.noOfPpl
          : total;
      }, 0);

      console.log("Assigned count for", driver.name, ":", assignedLoad);

      return {
        ...driver,
        availableCapacity: Math.max(0, driver.canTake - assignedLoad), // Ensure it doesn't go below 0
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name)); // Sorting by name

  const handleDriverChange = (e) => {
    setSelectedDriverId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedDriver = driversWithAvailableCapacity.find(
      (driver) => driver.participantId == selectedDriverId
    );

    if (!selectedDriver) {
      setWarning(`Selected driver not found.`);
      return;
    }

    if (
      !selectedDriver ||
      participants.length > selectedDriver.availableCapacity
    ) {
      setWarning(`Selected driver is maxed out.`);
      return;
    }

    onAssign(
      selectedDriverId,
      participants.map((p) => p.id)
    );
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-600 bg-opacity-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h2 className="text-lg font-semibold text-center mb-4">
          Assign Driver
        </h2>
        <p className="text-sm mb-4">
          Assign a driver for the following selected participants:
        </p>
        <ul className="list-disc list-inside mb-4">
          {participants.map((p) => (
            <li key={p.participantId}>
              {p.name} {p.noOfPpl > 1 ? `(${p.noOfPpl})` : ""} -{" "}
              {p.rideLocation}
            </li>
          ))}
        </ul>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <select
              value={selectedDriverId}
              onChange={handleDriverChange}
              className="select select-bordered w-full"
            >
              <option value="">Select a driver</option>
              {driversWithAvailableCapacity.map((driver) => (
                <option key={driver.participantId} value={driver.participantId}>
                  {driver.name} (Can take: {driver.availableCapacity})
                </option>
              ))}
            </select>
          </div>
          {warning && <p className="text-red-500 text-sm">{warning}</p>}
          <div className="flex justify-end space-x-2">
            <button type="button" onClick={onClose} className="btn btn-outline">
              Cancel
            </button>
            <button type="submit" className="btn bg-[#004a7e] text-white">
              Assign
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignDriverModal;
