import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    account_join_code: "",
    token: "",
    agreeToTerms: false,
  });
  const [accountName, setAccountName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const history = useHistory();
  const { token } = useParams(); // Capture the token from the URL

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (token) {
        setLoading(true);
        try {
          const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure this is set in your environment
          const response = await axios.get(
            `${apiBaseUrl}/api/fetch-user/${token}`
          );
          if (response.data.user) {
            setFormData({
              ...formData,
              name: response.data.user.name,
              email: response.data.user.email,
              account_join_code: response.data.user.account.account_join_code,
              token: token,
            });
            setAccountName(response.data.user.account.name);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          setErrorMessage("Failed to fetch user details.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserDetails();
  }, [token]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "agreeToTerms") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    if (
      !formData.password ||
      formData.password !== formData.password_confirmation
    ) {
      setErrorMessage("Passwords do not match");
      setLoading(false);
      return;
    }

    if (!formData.agreeToTerms) {
      setErrorMessage("Please agree to the terms and conditions");
      setLoading(false);
      return;
    }

    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure this is set in your environment
      const response = await axios.post(`${apiBaseUrl}/api/register`, formData);

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage("Registration successful. Please log in.");
        alert("Registration successful. Please log in.");
        history.push("/login");
      } else {
        setErrorMessage("Registration failed");
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message ||
          "An error occurred during registration."
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (!token || !accountName)
    return <div className="text-red-500 text-center">Invalid token.</div>;

  return (
    <div className="flex min-h-screen bg-white">
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/register.png"
          alt="Background"
        />
      </div>

      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Create your account for {accountName}
            </h2>
          </div>

          <div className="mt-8">
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            {successMessage && (
              <div className="text-green-500">{successMessage}</div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.email}
                  onChange={handleChange}
                  readOnly
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="password_confirmation"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password
                </label>
                <input
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="agreeToTerms" className="flex items-center">
                  <input
                    id="agreeToTerms"
                    name="agreeToTerms"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    checked={formData.agreeToTerms}
                    onChange={handleChange}
                  />
                  <span className="ml-2 text-sm text-gray-600">
                    I agree to the{" "}
                    <Link
                      to="/terms"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      Terms and Conditions
                    </Link>
                  </span>
                </label>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Register
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
