import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import useCRUD from "../../hooks/useCRUD";

import { formatDateWithDay } from "../../utils/dateUtils";
import Spinner from "../Spinner/Spinner";

export default function UpcomingEvents() {
  const {
    data: allEvents = [], // Provide an empty array as a default value
    isLoading,
    error,
    fetchItems: getEvents,
    deleteItem,
  } = useCRUD("/api/events");

  useEffect(() => {
    console.log("Fetching events...");
    getEvents().catch((err) => {
      console.log("Error fetching events:", err);
    });
  }, [getEvents]);

  const today = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 7);

  const events = allEvents
    ?.filter((event) => {
      const eventDate = new Date(event.dateOfOccurrence);
      return eventDate >= today && eventDate <= nextWeek;
    })
    .sort(
      (a, b) => new Date(a.dateOfOccurrence) - new Date(b.dateOfOccurrence)
    );

  if (isLoading) {
    return <Spinner />;
  }
  if (!events || events.length === 0) {
    return (
      <div className="card bg-white shadow-lg max-w-4xl mx-auto p-6 rounded-lg">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">
          No Upcoming Events This Week
        </h2>
        <p className="text-gray-600">
          There are no upcoming events scheduled for this week. Go to the Events
          tab to view all events.
        </p>
      </div>
    );
  }
  return (
    <div className="card bg-white shadow-lg max-w-4xl mx-auto p-6 rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-900 mb-4">
        Upcoming Events this Week
      </h2>
      <ol className="divide-y divide-gray-200">
        {events.map((event) => (
          <li
            key={event.id}
            className="py-4 flex items-center space-x-4 hover:bg-gray-50 rounded-lg -mx-4 px-4 cursor-pointer"
            onClick={() => (window.location.href = `/event/manage/${event.id}`)}
          >
            <FontAwesomeIcon
              icon={faCalendarAlt}
              size="2x"
              className="text-primary flex-shrink-0"
            />
            <div className="flex-1 min-w-0">
              <p className="text-lg font-medium text-gray-900 truncate">
                {event.title}
              </p>
              <p className="text-sm text-gray-500 truncate">{event.location}</p>
              <div className="mt-2 flex">
                <button className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="mr-2 h-5 w-5 text-green-600"
                  />
                  {formatDateWithDay(event.dateOfOccurrence)}
                </button>
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
