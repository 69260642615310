import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  isAuthenticated,
  isAdmin,
  logout as performLogout,
} from "../../utils/auth";
import AccountNotifications from "../Elements/AccountNotifications";

import { useAuth } from "../../context/AuthContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isLoggedIn, isAdminUser, updateAuthState } = useAuth();

  //get user info from local storage
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const userInitial = user?.name ? user.name.charAt(0) : "U";

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const logout = () => {
    performLogout();
    updateAuthState(); // Call this to update auth state across the app
  };

  return (
    <nav className="navbar bg-base-100 shadow-lg relative">
      <div className="flex-1 flex items-center">
        <img src="/logo.png" alt="My Event Ride Logo" className="h-10 mr-2" />
        <Link to="/" className="btn btn-ghost normal-case text-xl">
          My Event Ride
        </Link>
      </div>

      <div className="flex items-center lg:hidden">
        {/* AccountNotifications only in mobile view */}
        <AccountNotifications />
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="btn btn-square btn-ghost"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
              }
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } absolute top-full left-0 w-full bg-white shadow-md z-10 lg:hidden`}
      >
        <Link
          to="/"
          className="btn btn-ghost w-full justify-start"
          onClick={() => setIsMenuOpen(false)}
        >
          Home
        </Link>
        <Link
          to="/events"
          className="btn btn-ghost w-full justify-start"
          onClick={() => setIsMenuOpen(false)}
        >
          My Events
        </Link>
        <Link
          to="/groups"
          className="btn btn-ghost w-full justify-start"
          onClick={() => setIsMenuOpen(false)}
        >
          My Groups
        </Link>
        {isAdmin() && (
          <Link
            to="/account-settings"
            className="btn btn-ghost w-full justify-start"
            onClick={() => setIsMenuOpen(false)}
          >
            Account Settings
          </Link>
        )}
        <Link
          to="/profile"
          className="btn btn-ghost w-full justify-start"
          onClick={() => setIsMenuOpen(false)}
        >
          Profile
        </Link>
        <button className="btn btn-ghost w-full justify-start" onClick={logout}>
          Sign Out
        </button>
      </div>

      {/* Desktop Menu */}
      <div className="hidden lg:flex lg:items-center">
        <ul className="menu menu-horizontal p-0">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/events">My Events</Link>
          </li>
          <li>
            <Link to="/groups">My Groups</Link>
          </li>
          {/* Additional navigation items */}
        </ul>

        {/* AccountNotifications for desktop view */}
        <AccountNotifications />

        {/* User Profile and Logout for desktop */}
        <Menu as="div" className="relative ml-3">
          <Menu.Button className="flex text-sm rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span className="h-8 w-8 rounded-full flex items-center justify-center">
              {userInitial}
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 z-10 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } block px-4 py-2 text-sm text-gray-700`}
                  >
                    Your Profile
                  </Link>
                )}
              </Menu.Item>
              {isAdminUser && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/account-settings"
                      className={`${
                        active ? "bg-gray-100" : ""
                      } block px-4 py-2 text-sm text-gray-700`}
                    >
                      Account Settings
                    </Link>
                  )}
                </Menu.Item>
              )}

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={logout}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </nav>
  );
};

export default Navbar;
