// src/helpers/auth.js

export const isAuthenticated = () => {
  const token = localStorage.getItem("userToken");
  if (!token) {
    return false;
  }

  const expTimestamp = localStorage.getItem("userTokenExpiration");
  const exp = expTimestamp ? parseFloat(expTimestamp) : null;
  const currentTimeInSeconds = Date.now() / 1000;

  // Check if the token or expiration is not available, or if token is expired
  if (!exp || exp < currentTimeInSeconds) {
    // Consider removing the immediate logout here to prevent side effects.
    logout();
    return false;
  }

  //Get user info from local storage
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    const user = JSON.parse(userInfo);

    // Check if user is authenticated
    if (!user) {
      return false;
    }
  }

  return true;
};

export const isAdmin = () => {
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    const user = JSON.parse(userInfo);
    return user.isAdmin;
  }
  return false; // Returns false if userInfo is not present or if isAdmin is false
};

export const logout = () => {
  // Clear user-related data from local storage or state management

  console.log("Logout");
  localStorage.removeItem("userToken");
  localStorage.removeItem("userTokenExpiration");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("appVersion");

  // Redirect to the login page or home page
  window.location.href = "/login";
};
