// withFeatureRestriction.js
import React from "react";
import useFeatureAvailability from "../hooks/useFeatureAvailability";

const WithFeatureRestriction = ({ featureKey, children }) => {
  const { isFeatureAvailable, tooltipMessage } =
    useFeatureAvailability(featureKey);

  if (!isFeatureAvailable) {
    return (
      <div className="relative flex items-center">
        <div className="opacity-60">{children(true)}</div>
        <div className="absolute top-0 -right-2 flex items-center justify-center h-6 w-6 rounded-full bg-yellow-500 text-white">
          <span className="tooltip tooltip-left" data-tip={tooltipMessage}>
            <i className="fas fa-lock"></i>
          </span>
        </div>
      </div>
    );
  }

  return children();
};

export default WithFeatureRestriction;
