// dateUtils.js
import moment from "moment";

export const formatDateWithDay = (utcDateString) => {
  // Convert the UTC date string to the user's local time zone
  const localDate = moment.utc(utcDateString).local();
  return localDate.format("dddd, MMM D - h:mm A"); // Customize the format as needed
};

export const formatDateWithDayShortDay = (utcDateString) => {
  // Convert the UTC date string to the user's local time zone
  const localDate = moment.utc(utcDateString).local();
  return localDate.format("ddd, MMM D - h:mm A"); // Customize the format as needed
};

export const formatDate = (dateString) => {
  // Parse the date string as a moment object in UTC mode
  const date = moment.utc(dateString);

  // Convert to local time zone and format
  return date.local().format("MMMM D - h:mm A");
};
// export const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return `${date.toLocaleDateString("en-US", {
//     month: "long",
//     day: "numeric",
//   })} - ${date.toLocaleTimeString("en-US", {
//     hour: "numeric",
//     minute: "numeric",
//     hour12: true,
//   })}`;
// };

export const formatDateShort = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

// export const formatDateWithDay = (dateString) => {
//   const date = new Date(dateString);
//   const formattedDate = date.toLocaleDateString("en-US", {
//     weekday: "long", // Add this to get the day of the week
//     month: "short",
//     day: "numeric",
//   });
//   const formattedTime = date.toLocaleTimeString("en-US", {
//     hour: "numeric",
//     minute: "numeric",
//     hour12: true,
//   });

//   return `${formattedDate} - ${formattedTime}`;
// };

export const formatDateTimeForInput = (isoDateTime) => {
  const date = new Date(isoDateTime);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().slice(0, 16);
};

export const formatDateWithDayShort = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long", // Add this to get the day of the week
    month: "short",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${formattedDate} - ${formattedTime}`;
};

// utils/dateUtils.js
export const formatRelativeTime = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const formatDateDayMonthYear = (dateString) => {
  return moment(dateString).format("D MMMM YYYY");
};
