import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; // Import useLocation
import { isAuthenticated } from "../utils/auth";
import { useAuth } from "../context/AuthContext"; // Adjust the path as needed
import useCRUD from "../hooks/useCRUD";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const history = useHistory(); // Initialize useHistory hook
  const [successMessage, setSuccessMessage] = useState("");

  const { fetchData } = useCRUD("/api/user");

  useEffect(() => {
    if (isAuthenticated()) {
      history.push("/"); // Redirect to home page
    }
    const message = sessionStorage.getItem("successMessage");
    if (message) {
      setSuccessMessage(message);
      sessionStorage.removeItem("successMessage"); // Clear message after it's set
    }
  }, []);

  const { updateAuthState } = useAuth();

  const getUserProfile = async () => {
    console.log("Fetching user profile...");
    const fetchedData = await fetchData(); // Make sure fetchItems returns the fetched data

    if (fetchedData) {
      console.log("User:", fetchedData);
      const userData = {
        email: fetchedData?.email,
        name: fetchedData?.name,
        account: fetchedData?.account?.name,
        isAdmin: fetchedData?.isAdmin,
      };
      localStorage.setItem("userInfo", JSON.stringify(userData));

      const userData1 = {
        email: fetchedData.email,
        name: fetchedData.name,
        account: fetchedData.account.name,
        isAdmin: fetchedData.isAdmin,
        isSuperAdmin: fetchedData.isSuperAdmin,
        planName: fetchedData.account.planName,
        nextBillingDate: fetchedData.account.nextBillingDate,
      };

      updateAuthState();

      if (!userData1.planName) {
        if (userData1.isSuperAdmin) {
          // Redirect billing admin to plan selection or subscription status page
          history.push("/choose-plan");
        } else {
          // Redirect non-billing admins to an informational page
          history.push("/subscription-info");
        }
      } else {
        // User has an active subscription, continue to the requested page or home
        history.push(history.location.state?.from || "/");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any existing error message

    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL;

      // const response = await fetch(`${apiBaseUrl}/api/login`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ email, password }),
      // });

      const response = await axios.post(
        `${apiBaseUrl}/api/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        }
      );

      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("userToken", response.data.token);

        // Assuming `expirationDuration` is the duration in seconds (604800 in your case)
        const currentTimestampInSeconds = Date.now() / 1000;
        const expirationTimestamp =
          currentTimestampInSeconds + response.data.expires_in;

        localStorage.setItem(
          "userTokenExpiration",
          expirationTimestamp.toString()
        );

        await getUserProfile();

        // updateAuthState();

        // history.push("/"); // Redirect to home page
      } else {
        setErrorMessage(response.data.message || "Login failed");
        setPassword("");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("Request failed. Please try again later.");
      setPassword("");
    }
  };

  const handleForgotPassword = async () => {
    const emailToReset = prompt("Please enter your email address:");
    if (emailToReset) {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiBaseUrl}/api/forgot-password`, {
          email: emailToReset,
        });
        if (response.status === 200 || response.status === 201) {
          alert("Please check your email to reset your password.");
        } else {
          alert("Error sending password reset email. Please try again later.");
        }
      } catch (error) {
        console.error("Error during password reset request:", error);
        alert("There was an issue with your request. Please try again later.");
      }
    } else {
      alert("Please enter an email address.");
    }
  };

  return (
    <>
      <div
        className="flex min-h-screen bg-cover"
        style={{ backgroundImage: "url('/background.png')" }}
      >
        <div className="m-auto w-full max-w-md px-6 py-12 bg-white shadow-md overflow-hidden sm:rounded-lg">
          {/* Display success message from redirect */}
          {successMessage && (
            <div className="mb-4 text-green-600">{successMessage}</div>
          )}
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/logo.png"
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
            {errorMessage && (
              <div className="mt-2 text-center text-sm text-red-600">
                {errorMessage}
              </div>
            )}
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-customBlue hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-customBlue"
              >
                Sign in
              </button>
            </div>
          </form>

          <div className="mt-2 text-center text-sm text-gray-600">
            <div className="text-sm">
              <button
                type="button"
                onClick={handleForgotPassword}
                className="font-medium text-customBlue-600 hover:text-customBlue-500"
              >
                Forgot your password?
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
