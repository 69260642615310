// EventCard.js
import React, { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faEye,
  faCalendarAlt,
  faCalendar,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

const GroupCard = ({ group }) => {
  return (
    <div className="card w-full md:w-96 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-5 flex flex-col">
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faUserGroup}
            size="2x"
            className="text-primary mr-3"
          />
          <div className="flex-1">
            <h2 className="text-xl font-semibold text-gray-800">
              {group?.groupName}
            </h2>
            {/* <p className="text-gray-700 text-base">
                Participants:{" "}
                <span className="badge bg-blue-500 text-white">
                    {group?.participants_count}
                </span>
            </p> */}
          </div>
          {/* <button
            onClick={() => onDeleteClick(group.id)}
            className="text-gray-600 hover:text-gray-800 ml-auto"
          >
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          </button> */}
        </div>
        <div className="mt-2">
          <p className="text-gray-700 text-base">
            Participants:{" "}
            <span className="badge bg-blue-500 text-white">
              {group?.noOfParticipants}
            </span>
          </p>
        </div>

        <div className="mt-4">
          <Link
            to={`/group/${group.id}`}
            className="text-sm bg-primary text-white py-2 px-4 rounded"
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" /> Manage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GroupCard;
