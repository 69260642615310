import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./views/Login";
import Home from "./views/Home";
import Navbar from "./components/Layout/Navbar";
import Register from "./views/Register";
import EventsPage from "./components/Event/EventsPage";

import { PrivateRoute } from "./components/PrivateRoute";
import { AdminRoute } from "./components/PrivateRoute";

import AddEvent from "./components/Event/AddEvent";
import EditEvent from "./components/Event/EditEvent";
import ManageEventDashboard from "./components/EventDashboard/ManageEventDashboard";

import Groups from "./views/Groups";

import ManageGroup from "./components/Group/ManageGroup";
import DriverRsvp from "./views/DriverRsvp";
import ParticipantRsvp from "./views/ParticipantRsvp";
import DriverAssignmentList from "./views/DriverAssignmentList";
import SmsTemplateForm from "./components/Event/SmsTemplateForm";
import Profile from "./views/Profile";
import Settings from "./views/Settings";
import Footer from "./components/Layout/Footer";
import VersionHistory from "./views/VersionHistory";
import { AuthProvider, useAuth } from "./context/AuthContext";
import TermsAndConditions from "./views/TermsAndConditions";
import ResetPassword from "./views/ResetPassword";
import ChoosePlan from "./views/ChoosePlan";
import SubscriptionComplete from "./views/SubscriptionComplete";

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

// AppContent.js
function AppContent() {
  const { isLoggedIn, userPlan } = useAuth(); // Assume userPlan is true if the user has an active plan

  return (
    <div className="App h-screen grid grid-rows-[auto,1fr,auto]">
      {isLoggedIn && userPlan && <Navbar />}
      <div className="content">
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/event/create" component={AddEvent} />
          <PrivateRoute path="/events" component={EventsPage} />
          <PrivateRoute path="/event/edit/:id" component={EditEvent} />
          <PrivateRoute
            path="/event/sms-template/:id"
            component={SmsTemplateForm}
          />
          <PrivateRoute
            path="/event/manage/:id"
            component={ManageEventDashboard}
          />

          <PrivateRoute path="/groups" component={Groups} />

          <PrivateRoute path="/group/:id" component={ManageGroup} />

          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute
            path="/subscription/complete"
            component={SubscriptionComplete}
          />
          <Route path="/choose-plan" component={ChoosePlan} />

          <AdminRoute path="/account-settings" component={Settings} />

          <Route path="/login" component={Login} />
          {/* <AdminRoute path="/register" component={Register} /> */}
          <Route path="/register/:token" component={Register} />
          <Route path="/reset-password/:tokenEmail" component={ResetPassword} />
          <Route path="/terms" component={TermsAndConditions} />

          <Route path="/d/:uniqueCode" component={DriverRsvp} />
          <Route path="/p/:uniqueCode" component={ParticipantRsvp} />
          <Route path="/r/:uniqueCode" component={DriverAssignmentList} />
          <Route path="/version-history" component={VersionHistory} />

          <Route path="*" component={() => <h1>Page Not Found</h1>} />
        </Switch>
      </div>
      {isLoggedIn && userPlan && <Footer />}
    </div>
  );
}

export default App;
