import React, { useState, useEffect, useMemo } from "react";
import Spinner from "../Spinner/Spinner"; // Make sure you have this component for loading indication
import useCRUD from "../../hooks/useCRUD";
import AddOrEditParticipantModal from "../EventDashboard/AddorEditParticipantModal";

const Drivers = ({ event, drivers = [], refreshData, groupId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [error, setError] = useState(null);

  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });

  const { addItem, updateItem, deleteItem } = useCRUD("/api/participants");
  console.log("drivers", drivers);

  const sortedDrivers = useMemo(() => {
    let sortableItems = [...drivers];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [drivers, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleAddNewDriverClick = () => {
    setIsModalOpen(true);
    setModalData(null); // Initialize data for new driver
  };

  const handleEditClick = (driver) => {
    setIsModalOpen(true);
    setModalData({ ...driver, isDriver: true }); // Set modal for editing a driver
  };

  const handleSave = async (data) => {
    try {
      setIsLoading(true);
      setError(""); // Reset error message on new save attempt

      let response; // This will store the response from your save functions

      if (modalData) {
        // Editing an existing participant
        response = await updateItem(modalData.id, data);
      } else {
        // Adding a new participant
        data.participantGroupId = groupId;
        response = await addItem(data);
      }

      // Check the response to ensure it was successful
      // This assumes your addItem/updateItem functions return some success indicator
      // Adjust the following line according to your actual API response structure
      if (response && response.data) {
        console.log("Participant saved successfully:", data);
        // Optionally, refresh your participants list here
      } else {
        // Handle unsuccessful save
        // Adjust the following line according to your actual API response structure
        throw new Error(
          response.message || "An error occurred while saving the participant."
        );
      }
    } catch (error) {
      console.error("Error saving participant:", error);
      setError(error.message || "Error saving participant. Please try again.");
    } finally {
      setIsModalOpen(false); // Close the modal after attempting save
      refreshData(); // Refresh data regardless of success/failure
      setIsLoading(false);
    }
  };

  const handleDeleteClick = async (driver) => {
    try {
      setIsLoading(true);
      await deleteItem(driver.id);
      console.log("Participant deleted successfully:", driver);
      // Optionally, refresh your participants list here
    } catch (error) {
      console.error("Error deleting participant:", error);
    } finally {
      refreshData();
      setIsLoading(false);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      {isLoading && <Spinner />}

      {error && <div className="text-red-500">{error}</div>}

      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">{drivers.length} Drivers</h3>
          <button
            className="btn bg-[#004a7e] text-white mt-4 hover:bg-[#004a7e] hover:text-white btn-sm"
            onClick={handleAddNewDriverClick}
          >
            + Add New Driver
          </button>
        </div>

        {isModalOpen && (
          <AddOrEditParticipantModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={handleSave}
            initialData={modalData}
            isDriver={true}
            event={event}
          />
        )}

        {sortedDrivers.length > 0 ? (
          <div className="">
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => requestSort("name")}>
                    Driver{" "}
                    {sortConfig.key === "name" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th className="hidden md:table-cell"> Phone</th>
                  <th onClick={() => requestSort("canTake")}>
                    Can Take{" "}
                    {sortConfig.key === "canTake" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedDrivers.map((driver) => (
                  <tr key={driver.id}>
                    <td>
                      <div className="font-bold">
                        {driver.name}{" "}
                        {driver.pauseNotifications && (
                          <span className="ml-2 bg-red-500 text-white py-1 px-2 text-xs rounded">
                            Paused
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="hidden md:table-cell">
                      <div className="text-gray-500">{driver.phone}</div>
                    </td>
                    <td>{driver.canTake}</td>
                    <td>
                      <div className="dropdown dropdown-end">
                        <button
                          tabIndex="0"
                          className="btn btn-ghost btn-xs m-1"
                        >
                          ...
                        </button>
                        <ul
                          tabIndex="0"
                          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                        >
                          <li>
                            <a
                              href="#"
                              onClick={() => handleEditClick(driver)}
                              className="justify-between"
                            >
                              Edit
                            </a>
                          </li>
                          <li>
                            <button onClick={() => handleDeleteClick(driver)}>
                              Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No drivers to display.</p>
        )}
      </div>
    </div>
  );
};

export default Drivers;
