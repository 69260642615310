import React, { useState } from "react";

const TimeModal = ({ isOpen, onClose, onSave }) => {
  const [time, setTime] = useState("");

  function to12HourFormat(time24) {
    const [hours24, minutes] = time24.split(":");
    const hours = parseInt(hours24, 10);
    const suffix = hours >= 12 ? "PM" : "AM";
    const hours12 = ((hours + 11) % 12) + 1;
    return `${hours12}:${minutes} ${suffix}`;
  }

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-4">
        <h2 className="text-lg font-bold mb-4">Set Pickup Time</h2>
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className="border-2 border-gray-300 rounded p-2 mb-4 w-full"
        />
        <div className="flex justify-end space-x-4">
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => onSave(to12HourFormat(time))}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeModal;
