// src/components/Footer.js

import React from "react";
import { Link } from "react-router-dom";
import versionInfo from "../../version.json"; // Import the entire JSON object

const Footer = () => {
  return (
    <footer className="bg-primary text-white text-center p-4">
      <p>
        My Event Ride © {new Date().getFullYear()} Version:{" "}
        <Link to="/version-history" className="text-white underline">
          {versionInfo.version}
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
