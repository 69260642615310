import React, { useState } from "react";
import DriverCard from "./DriverCard";
import Spinner from "../Spinner/Spinner";
import useCRUD from "../../hooks/useCRUD";

const RidesList = ({
  eventId,
  eventParticipations,
  refreshData,
  isEventExpired,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const ridesByDriver = eventParticipations.reduce((acc, participant) => {
    if (participant.isDriver || !participant.assignedDriverId) return acc;
    const driverId = participant.assignedDriverId.id;
    acc[driverId] = acc[driverId] || [];
    acc[driverId].push(participant);
    return acc;
  }, {});
  console.log("Rides by driver:", ridesByDriver);

  const { updateItem: updateEventParticipation } = useCRUD(
    "/api/event-participations"
  );

  const { addItem: sendNotification } = useCRUD(
    "/api/event-participations/send-notification-to-driver"
  );

  const { addItem: sendNotificationToAllDrivers } = useCRUD(
    "/api/event-participations/send-notification-to-all-drivers"
  );

  const drivers = eventParticipations.filter(
    (p) => p.isDriver && p.availability !== "No"
  );

  // Filtering drivers who are available and have been assigned at least one participant
  const driversWithParticipants = eventParticipations.filter(
    (p) =>
      p.isDriver &&
      p.availability !== "No" &&
      ridesByDriver[p.participantId]?.length > 0
  );

  const allParticipants = eventParticipations.filter((p) => !p.isDriver);

  const handleSendNotification = async (driverId) => {
    setIsLoading(true);
    console.log("Sending notification to", driverId);

    try {
      const body = {
        id: driverId,
      };
      await sendNotification(body);
      console.log("Notification sent successfully");
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      await refreshData(); // Refresh the list to reflect changes

      setIsLoading(false);
    }

    // Implement your notification logic here
    setIsLoading(false);
  };

  const handleSendNotificationToAllDrivers = async () => {
    setIsLoading(true);

    try {
      const body = {
        id: eventId,
      };
      await sendNotificationToAllDrivers(body);
      console.log("Notification sent successfully");
    } catch (error) {
      console.error("Error sending notification:", error);
    } finally {
      await refreshData(); // Refresh the list to reflect changes

      setIsLoading(false);
    }

    // Implement your notification logic here
    setIsLoading(false);
  };

  const onManagePickups = async (driverId, participantId, action) => {
    setIsLoading(true);
    try {
      // Assuming you have a function to update the event participation list
      // This could involve calling an API to update the backend
      if (action === "add") {
        // Add participant to driver's list

        await updateEventParticipation(participantId.id, {
          assignedDriverId: driverId,
        });

        console.log(
          `Adding participant ${participantId} to driver ${driverId}`
        );
        // Your logic to update the assignment
      } else if (action === "remove") {
        // Remove participant from driver's list

        await updateEventParticipation(participantId.id, {
          assignedDriverId: null,
        });

        console.log(
          `Removing participant ${participantId} from driver ${driverId}`
        );
        // Your logic to update the assignment
      }
    } catch (error) {
      console.error("Error managing pickups:", error);
    } finally {
      await refreshData(); // Refresh the list to reflect changes

      setIsLoading(false);
    }
  };

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Event Rides</h3>
          <div
            className="tooltip"
            data-tip="Sends a notification to all drivers with a link to their pickup list. Once Driver confirms, a notification will go out to students"
          >
            {driversWithParticipants?.length > 0 && (
              <button
                className="btn bg-[#004a7e] text-white btn-sm hover:bg-[#004a7e] hover:text-white flex items-center justify-center gap-2"
                onClick={() => handleSendNotificationToAllDrivers()}
                disabled={isEventExpired}
              >
                <i className="fas fa-bell"></i>
                Notify Drivers
              </button>
            )}
          </div>
        </div>
        {driversWithParticipants?.length < 1 && (
          <p className="text-center text-gray-600">
            No rides assigned. Click Assign Driver to assign drivers to
            participants.
          </p>
        )}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {driversWithParticipants &&
            driversWithParticipants.map((driver) => (
              <DriverCard
                key={driver.id}
                driver={driver}
                rides={ridesByDriver[driver.participantId] || []}
                onSendNotification={handleSendNotification}
                isLoading={isLoading}
                allParticipants={allParticipants}
                onManagePickups={onManagePickups}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RidesList;
