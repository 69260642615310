const RsvpSubmitted = ({ message }) => {
  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        backgroundImage: "url('your-background-image-url-here')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="card bg-white shadow-lg p-4">
        <div className="alert alert-success flex items-center space-x-2">
          <svg
            className="shrink-0 h-6 w-6 text-info-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{message}</span>
        </div>
      </div>
    </div>
  );
};

export default RsvpSubmitted;
