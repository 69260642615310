import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import { formatDate } from "../utils/dateUtils"; // Assuming you have a utility function for formatting dates
import Spinner from "../components/Spinner/Spinner";

import RsvpSubmitted from "../components/Event/RsvpSubmitted";
const DriverRsvp = () => {
  const { uniqueCode } = useParams();

  const {
    data: eventParticipation,
    fetchItems: fetchEventParticipation,
    isLoading,
    error,
  } = useCRUD(`/api/event-participations/rsvp/${uniqueCode}`);

  const { updateItem } = useCRUD(`/api/event-participations/rsvp`);
  const [initialMessageForAdmin, setInitialMessageForAdmin] = useState("");

  const [availability, setAvailability] = useState("");
  const [messageForAdmin, setMessageForAdmin] = useState("");

  const [initialCanTake, setInitialCanTake] = useState("");

  const [canTake, setCanTake] = useState(initialCanTake);

  const [rsvpStatus, setRsvpStatus] = useState({
    submitted: false,
    message: "",
  });

  useEffect(() => {
    document.body.style.backgroundImage = "url('/background.png')";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";

    // Cleanup function to remove the style
    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  useEffect(() => {
    fetchEventParticipation();
  }, [fetchEventParticipation]);

  useEffect(() => {
    if (eventParticipation) {
      const initialCanTakeValue = eventParticipation.canTake;
      setInitialCanTake(initialCanTakeValue); // Though this is not strictly necessary if you're directly setting canTake
      setCanTake(initialCanTakeValue); // Directly set canTake here
      setAvailability(eventParticipation.availability);
      setMessageForAdmin(eventParticipation.messageForAdmin || ""); // Initialize messageForAdmin
      setInitialMessageForAdmin(eventParticipation.messageForAdmin || "");
    }
  }, [eventParticipation]);

  const handleRsvpChange = async (e) => {
    e.preventDefault();

    // Collect current form values
    const currentAvailability = availability;
    const currentCanTake = canTake || initialCanTake;

    // Prepare the payload, including canTake only if it was modified
    const updatePayload = {
      availability: currentAvailability,
      // Include canTake if modified
      ...(currentCanTake !== undefined &&
        currentCanTake.toString() !== initialCanTake.toString() && {
          canTake: currentCanTake,
        }),
      // Include messageForAdmin if modified
      ...(messageForAdmin !== initialMessageForAdmin && { messageForAdmin }),
    };

    try {
      await updateItem(uniqueCode, updatePayload);
      // Update local state to show the message and hide the form
      setRsvpStatus({
        submitted: true,
        message: "Your RSVP has been recorded.",
      });
    } catch (error) {
      console.error("Error updating RSVP:", error);
      setRsvpStatus({
        submitted: false,
        message: "Failed to record your RSVP. Please try again.",
      });
    }
  };

  // If RSVP has been submitted, show the message instead of the form
  if (rsvpStatus.submitted) {
    return <RsvpSubmitted message={rsvpStatus.message} />;
  }

  if (isLoading) return <Spinner />;
  if (error)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  if (!eventParticipation)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  if (!eventParticipation.isDriver)
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );

  const { name, eventDetails, isDriver } = eventParticipation;

  const currentDate = new Date();
  const eventDate = new Date(eventDetails.dateOfOccurrence);

  // Add 12 hours to eventDate in UTC
  eventDate.setUTCHours(eventDate.getUTCHours() + 12);

  // If you want to compare the current date in UTC as well
  // Convert currentDate to UTC by creating a new Date object in UTC
  const currentUTCDate = new Date(
    Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    )
  );

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        backgroundImage: "url('/background.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow">
        {eventDate < currentUTCDate ? (
          <div className="text-center text-red-500 p-4">
            Event RSVP expired.
          </div>
        ) : (
          isDriver && (
            <form onSubmit={handleRsvpChange} className="space-y-4">
              {/* Display accountLogo if present */}
              {eventParticipation.accountLogo && (
                <div className="text-center mb-4">
                  <img
                    src={eventParticipation.accountLogo}
                    alt="Account Logo"
                    className="mx-auto"
                    style={{ maxWidth: "150px", maxHeight: "150px" }} // Limiting size
                  />
                </div>
              )}
              <h1 className="text-xl font-semibold">Hi {name},</h1>
              <p>
                Are you available to provide rides for{" "}
                <strong>{eventDetails.title}</strong> on{" "}
                <strong>{formatDate(eventDetails.dateOfOccurrence)}</strong>?
              </p>
              <div className="flex gap-4 justify-center">
                <div
                  className={`p-4 rounded-lg cursor-pointer w-1/2 text-center ${
                    availability === "Yes"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setAvailability("Yes")}
                >
                  Yes
                </div>
                <div
                  className={`p-4 rounded-lg cursor-pointer w-1/2 text-center ${
                    availability === "No"
                      ? "bg-red-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setAvailability("No")}
                >
                  No
                </div>
              </div>
              {availability === "Yes" && (
                <div>
                  <label htmlFor="canTake" className="block mb-2">
                    Can take (No. of people):
                  </label>
                  <input
                    type="number"
                    name="canTake"
                    value={canTake}
                    onChange={(e) => setCanTake(e.target.value)}
                    className="input input-bordered w-full max-w-xs"
                  />
                </div>
              )}
              <div>
                <input
                  type="text"
                  name="messageForAdmin"
                  value={messageForAdmin}
                  onChange={(e) => setMessageForAdmin(e.target.value)}
                  className="input input-bordered w-full"
                  placeholder="Enter message for admin if needed"
                />
              </div>
              <button type="submit" className="btn btn-primary w-full">
                Submit
              </button>
            </form>
          )
        )}
      </div>
    </div>
  );
};

export default DriverRsvp;
